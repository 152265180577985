import i18n from '@/language/i18n';
import dayjs from 'dayjs';

// 转换时间
export const parseTime = (
    time?: object | string | number | null,
    cFormat?: string
): string | null => {
    if (time === undefined || !time) {
        return null;
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date: Date;
    if (typeof time === 'object') {
        date = time as Date;
    } else {
        if (typeof time === 'string') {
            if (/^[0-9]+$/.test(time)) {
                // support "1548221490638"
                time = parseInt(time);
            } else {
                // support safari
                // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
                time = time.replace(new RegExp(/-/gm), '/');
            }
        }
        if (typeof time === 'number' && time.toString().length === 10) {
            time = time * 1000;
        }
        date = new Date(time);
    }
    const formatObj: { [key: string]: number } = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    };
    const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key];
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value];
        }
        return value.toString().padStart(2, '0');
    });
    return timeStr;
};

export const isTrident = () => {
    const userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    return userAgent.indexOf('Trident') > -1 || userAgent.indexOf('Edge') > -1; //判断是否IE内核
};
export const deepClone = (source: object) => {
    if (!source && typeof source !== 'object') {
        throw new Error('error arguments', 'deepClone');
    }
    const targetObj = source.constructor === Array ? [] : {};
    Object.keys(source).forEach((keys) => {
        if (source[keys] && typeof source[keys] === 'object') {
            targetObj[keys] = deepClone(source[keys]);
        } else {
            targetObj[keys] = source[keys];
        }
    });
    return targetObj;
};
// 下载文件
export const downloadFile = (inputData: any, inputName?: any) => {
    const blob = inputData;
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    const urlObject = window.URL || window.webkitURL || window;
    downloadLink.href = urlObject.createObjectURL(blob);
    if (inputName) {
        downloadLink.download = inputName;
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href); // 释放URL 对象
    document.body.removeChild(downloadLink);
};

/*
 * 本页面下载文件，无法监听下载回调
 */
export function downloadLinkFile(
    url: string,
    inputName?: string,
    isPic = false
) {
    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';
    downloadLink.href = url;
    if (inputName) {
        if (isPic) {
            downloadLink.download = inputName + '.jpg';
        } else {
            downloadLink.download = inputName;
        }
    } else {
        downloadLink.download = '';
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href); // 释放URL 对象
    document.body.removeChild(downloadLink);
}

//计算年龄
export const getAge = (strAge: any) => {
    if (strAge === 0) {
        return '未知';
    }
    const birArr = new Date(strAge * 1000);
    const birYear = birArr.getFullYear();
    const birMonth = birArr.getMonth() + 1; //记得加1
    const birDay = birArr.getDate();
    const date = new Date();
    const nowYear = date.getFullYear();
    const nowMonth = date.getMonth() + 1; //记得加1
    const nowDay = date.getDate();
    let returnAge;
    returnAge = nowYear - birYear;
    if (nowMonth < birMonth) {
        returnAge++;
    } else if (birDay < nowDay) {
        returnAge++;
    }
    return returnAge;
};

export const getSex = (val: any) => {
    if (val === 0) {
        return '未知';
    } else if (val === 1) {
        return '男';
    } else if (val === 2) {
        return '女';
    } else {
        return '未说明';
    }
};
//日期转换格式
export function formatDate(date: Date, connector: string) {
    var result = '';
    result += date.getFullYear() + connector;
    result +=
        (Number(date.getMonth()) + 1 < 10
            ? '0' + (Number(date.getMonth()) + 1)
            : Number(date.getMonth()) + 1) + connector;
    result +=
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    result +=
        (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    result +=
        (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
        ':';
    result +=
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return result;
}
//日期转换格式-返回年月日
export function formatDateYMD(date: Date, connector: string) {
    var result = '';
    result += date.getFullYear() + connector;
    result +=
        (Number(date.getMonth()) + 1 < 10
            ? '0' + (Number(date.getMonth()) + 1)
            : Number(date.getMonth()) + 1) + connector;
    result +=
        (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    return result;
}
//base64转码（压缩完成后的图片为base64编码，这个方法可以将base64编码转回file文件）
export function dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
export const getBase64: any = (url: any) => {
    const baseURL =
        url.indexOf('http') > -1
            ? ''
            : import.meta.env.VITE_APP_BASE_API?.toString();

    return new Promise((resolve, reject) => {
        window.URL = window.URL || window.webkitURL;
        var xhr = new XMLHttpRequest();
        xhr.open('get', baseURL + url, true);
        // 至关重要
        xhr.responseType = 'blob';
        xhr.onload = function () {
            if (this.status === 200) {
                //得到一个blob对象
                var blob = this.response;
                // 至关重要
                let oFileReader = new FileReader();
                oFileReader.onloadend = function (e: any) {
                    // 此处拿到的已经是 base64的图片了
                    let base64 = e.target.result;
                    // base64 = base64.replace("data:image/jpeg;base64,", "");
                    resolve(base64);
                };
                oFileReader.readAsDataURL(blob);
                //====为了在页面显示图片，可以删除====
                var img = document.createElement('img');
                img.onload = function () {
                    window.URL.revokeObjectURL(img.src); // 清除释放
                };
                let src = window.URL.createObjectURL(blob);
                img.src = src;
            }
        };
        xhr.onerror = (err) => {
            reject(err);
        };
        xhr.send();
    });
};

// 文件读取
export function fileParse(file, type = 'base64') {
    return new Promise((resolve) => {
        let fileRead = new FileReader();
        if (type === 'base64') {
            fileRead.readAsDataURL(file);
        } else if (type === 'buffer') {
            fileRead.readAsArrayBuffer(file);
        }
        fileRead.onload = (ev) => {
            resolve(ev.target.result);
        };
    });
}

// 将秒转换成几天几小时几分钟几秒
export function formatSecToStr(seconds: number) {
    let daySec = 24 * 60 * 60;
    let hourSec = 60 * 60;
    let minuteSec = 60;
    let dd = Math.floor(seconds / daySec);
    let hh = Math.floor((seconds % daySec) / hourSec);
    let mm = Math.floor((seconds % hourSec) / minuteSec);
    let ss = seconds % minuteSec;
    if (dd > 0) {
        return dd + '天' + hh + '小时' + mm + '分钟' + ss + '秒';
    } else if (hh > 0) {
        return hh + '小时' + mm + '分钟' + ss + '秒';
    } else if (mm > 0) {
        return mm + '分钟' + ss + '秒';
    } else {
        return ss + '秒';
    }
}

//将秒数转换为天时分秒格式
export function setSeconds(value) {
    var result = '';
    var sencond = parseInt(value);
    //3600秒等于60分钟等于1小时
    if (sencond > 3600 * 24) {
        //表示大于一天
        var day = sencond / (3600 * 24);
        sencond = sencond % (3600 * 24); //求天数整除外剩余的秒数
        var hour = sencond / 3600; //1小时==60分钟==3600秒，所以剩余的秒数÷3600= 得到几个小时数
        var minute = (sencond % 3600) / 60; //求小时数整除外剩余的秒数， 秒数÷60秒 = 得到几分钟
        result =
            parseInt(day) +
            (parseInt(day) > 1
                ? i18n.global.t('content.general.days')
                : i18n.global.t('content.general.day')) +
            parseInt(hour) +
            (parseInt(hour) > 1
                ? i18n.global.t('content.general.hours')
                : i18n.global.t('content.general.hour')) +
            parseInt(minute) +
            (parseInt(minute) > 1
                ? i18n.global.t('content.general.minutes')
                : i18n.global.t('content.general.minute'));
    } else if (sencond > 3600) {
        //表示大于一个小时
        const hourTmp = sencond / 3600;
        const minuteTmp = (sencond % 3600) / 60; //求小时数整除外剩余的秒数， 秒数÷60秒 = 得到几分钟
        result =
            parseInt(hourTmp) +
            (parseInt(hourTmp) > 1
                ? i18n.global.t('content.general.hours')
                : i18n.global.t('content.general.hour')) +
            parseInt(minuteTmp) +
            (parseInt(minuteTmp) > 1
                ? i18n.global.t('content.general.minutes')
                : i18n.global.t('content.general.minute'));
    } else if (sencond > 60) {
        //表示大于一分钟
        const minuteTmp = sencond / 60;
        var sec = sencond % 60; //求分钟数整除外剩余的秒数
        result =
            parseInt(minuteTmp) +
            (parseInt(minuteTmp) > 1
                ? i18n.global.t('content.general.minutes')
                : i18n.global.t('content.general.minute')) +
            parseInt(sec) +
            (parseInt(sec) > 1
                ? i18n.global.t('content.general.seconds')
                : i18n.global.t('content.general.second'));
    } else {
        //不大于一天、不大于一个小时、也不大于一分钟，那就是秒数
        result =
            '' +
            parseInt(sencond) +
            (parseInt(sencond) > 1
                ? i18n.global.t('content.general.seconds')
                : i18n.global.t('content.general.second'));
    }
    return result;
}

export function getTimeAll(time) {
    const year = time.getFullYear();
    const month =
        time.getMonth() + 1 < 10
            ? '0' + (time.getMonth() + 1)
            : time.getMonth() + 1;
    const date = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
    const hh = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
    const mm =
        time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
    const ss =
        time.getSeconds() < 10 ? '0' + time.getSeconds() : time.getSeconds();

    return year + '-' + month + '-' + date + ' ' + hh + ':' + mm + ':' + ss;
}
//当前时区的时间戳转换目标时区的时间戳
// timestamp:当前时区的时间戳
// timeZone：目标时区
export const timeToTargetTime = (targetZone: any, timestamp: any) => {
    //兼容13位与10位时间戳
    let tempTimeStamp;
    if (String(timestamp).length === 13) {
        tempTimeStamp = timestamp;
    } else {
        tempTimeStamp = timestamp * 1000;
    }
    return (
        tempTimeStamp -
        (targetZone * 60 - -new Date().getTimezoneOffset()) * 60 * 1000
    );
};

// 当前时区的时间戳转目标时区的时间
// timestamp:当前时区的时间戳
// timeZone：目标时区
export const timeToTargetDate = (targetZone: any, timestamp: any) => {
    let tempTimeStamp;
    if (String(timestamp).length === 13) {
        tempTimeStamp = timestamp;
    } else {
        tempTimeStamp = timestamp * 1000;
    }
    const localZone = -new Date().getTimezoneOffset();
    const localTime = tempTimeStamp + (targetZone * 60 - localZone) * 60 * 1000;
    return dayjs(new Date(localTime)).format('YYYY/MM/DD HH:mm:ss');
};
