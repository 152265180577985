<template>
    <bre-breadcrumb class="container-breadcrumb">
        <bre-breadcrumb-item v-for="(item,index) in items" :key="item">
            {{t(item)}}<icon-right v-if="item!=='content.router.empty'&&index!==items.length-1"></icon-right>
        </bre-breadcrumb-item>
        <template #separator>
            <p></p>
            <!-- <icon-right></icon-right> -->
        </template>
    </bre-breadcrumb>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    props: {
        items: {
            type: Array as PropType<string[]>,
            default() {
                return [];
            }
        }
    },
    setup() {
        const { t } = useI18n();
        return {
            t
        };
    }
});
</script>

<style scoped lang="scss">
.container-breadcrumb {
    margin: 16px 0 16px 10px;
    :deep(.arco-breadcrumb-item) {
        color: rgb(var(--gray-6));
        &:last-child {
            color: rgb(var(--gray-8));
        }
    }
    svg {
        margin-left: 15px;
    }
}
</style>
