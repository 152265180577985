import { constantRoutes } from '@/router/index.ts';
import { defineStore } from 'pinia';
import Layout from '@/layout/index.vue';
import router from '@/router';

export const useRouterStore = defineStore({
    id: 'router', // id必填，且需要唯一
    state: () => ({
        routes: [],
        hideRoutesStateData: 'open'
    }),
    actions: {
        initRoutes() {
            this.routes = constantRoutes;
        },
        initChildRoutes(str: string) {
            // 获取一级路由
            let path = '/' + str.split('/')[1];
            if (path) {
                let len = this.routes.length;
                for (let i = 0; i < len; i++) {
                    if (this.routes[i].path === path) {
                        this.childRoutes = this.routes[i].children;
                        this.selectPath = path; //一级路由默认选择
                        this.selectChildPath = str;
                        return;
                    }
                }
            }
        },
        addAsyncRoutes(routes: any) {
            this.routes.push(routes);
        },
        // 控制隐藏界面显隐
        addHideRoutesData() {
            // this.hideRouteState = 'open';
            sessionStorage.setItem('hideRouteStateData', 'open');
            this.routes[12].children.push({
                path: '/system-config/resource',
                component: () =>
                    import('@/views/system-config/resource-list/index.vue'),
                name: 'resourceList',
                meta: {
                    title: 'content.router.resourceList',
                    roles: ['11080000']
                }
            });
            if (sessionStorage.getItem('isfinfo') !== '0') {
                this.routes[12].children.push({
                    path: '/system-config/pk',
                    component: () =>
                        import('@/views/system-config/pk-config/index.vue'),
                    name: 'pk',
                    meta: {
                        title: 'content.router.PK',
                        roles: ['11080000']
                    }
                });
            }
            this.routes[12].children.push({
                path: '/system-config/service',
                component: () =>
                    import('@/views/system-config/service-list/index.vue'),
                name: 'serviceList',
                meta: {
                    title: 'content.router.serviceList',
                    roles: ['11080000']
                }
            });
            this.routes[12].children.push({
                path: '/system-config/api-status',
                component: () =>
                    import('@/views/system-config/api-status/index.vue'),
                name: 'apiStatus',
                meta: {
                    title: 'content.router.apiStatus',
                    roles: ['11080000']
                }
            });

            let children = [
                {
                    path: '/system-config/resource',
                    component: () =>
                        import('@/views/system-config/resource-list/index.vue'),
                    name: 'resourceList',
                    meta: {
                        title: 'content.router.resourceList',
                        roles: ['11080000']
                    }
                },
                {
                    path: '/system-config/pk',
                    component: () =>
                        import('@/views/system-config/pk-config/index.vue'),
                    name: 'pk',
                    meta: {
                        title: 'content.router.PK',
                        roles: ['11080000']
                    }
                },
                {
                    path: '/system-config/service',
                    component: () =>
                        import('@/views/system-config/service-list/index.vue'),
                    name: 'serviceList',
                    meta: {
                        title: 'content.router.serviceList',
                        roles: ['11080000']
                    }
                },
                {
                    path: '/system-config/api-status',
                    component: () =>
                        import('@/views/system-config/api-status/index.vue'),
                    name: 'apiStatus',
                    meta: {
                        title: 'content.router.apiStatus',
                        roles: ['11080000']
                    }
                }
            ];
            if (sessionStorage.getItem('isfinfo') === '0') {
                children.splice(1, 1);
            }
            router.addRoute({
                path: '/system-config',
                component: Layout,
                meta: {
                    title: 'content.router.system',
                    icon: 'system-config',
                    roles: ['11000000']
                },
                children: children
            });
        },
        hideRoutesData() {
            // for (let i = 0; i < this.routes.length; i++) {
            //     if (this.routes[i].path === '/system-config') {
            //         this.routes[i].children.splice(4, 5);
            //     }
            // }
            sessionStorage.setItem('hideRouteStateData', 'hide');
            router.push('/id-manage').then(() => {
                location.reload();
            });
        },
        addWorkClothesRoutes() {
            // sessionStorage.setItem('hideWorkRouteState', 'open');
            // this.routes[8]?.children.unshift({
            //     path: '/guard/work-cloth',
            //     component: () =>
            //         import('@/../views/guard/work-cloth/index.vue'),
            //     name: 'workCloth',
            //     meta: {
            //         title: 'content.router.workCloth',
            //         roles: ['12030000']
            //     }
            // });
            // router.addRoute({
            //     path: '/guard',
            //     component: Layout,
            //     meta: {
            //         title: 'content.router.guard',
            //         icon: 'guard',
            //         roles: [
            //             '12000000',
            //             '12010000',
            //             '12020000',
            //             '12030000',
            //             '12040000',
            //             '12050000'
            //         ]
            //     },
            //     children: [
            //         {
            //             path: '/guard/work-cloth',
            //             component: () =>
            //                 import('@/views/guard/work-cloth/index.vue'),
            //             name: 'workCloth',
            //             meta: {
            //                 title: 'content.router.workCloth',
            //                 roles: ['12030000']
            //             }
            //         }
            //     ]
            // });
            // this.routes[9].children.unshift({
            //     path: '/data-search/work-cloth-snapshot',
            //     component: () =>
            //         import('@/views/data-search/work-cloth-snapshot/index.vue'),
            //     name: 'workClothSnapshot',
            //     meta: {
            //         title: 'content.router.workClothSnapshot',
            //         roles: ['14020000']
            //     }
            // });
            // router.addRoute({
            //     path: '/data-search',
            //     component: Layout,
            //     meta: {
            //         title: 'content.router.search',
            //         icon: 'data-search',
            //         roles: [
            //             '14000000',
            //             '14010000',
            //             '14020000',
            //             '14030000',
            //             '14040000'
            //         ]
            //     },
            //     children: [
            //         {
            //             path: '/data-search/work-cloth-snapshot',
            //             component: () =>
            //                 import(
            //                     '@/views/data-search/work-cloth-snapshot/index.vue'
            //                 ),
            //             name: 'workClothSnapshot',
            //             meta: {
            //                 title: 'content.router.workClothSnapshot',
            //                 roles: ['14020000']
            //             }
            //         }
            //     ]
            // });
        },
        // 隐藏工服相关路由：智能布控的工服库，数据检索的工服抓拍
        hideWorkClothesRoutes() {
            // sessionStorage.setItem('hideWorkRouteState', 'hide');
            // this.routes[8].children.splice(0, 1);
            // this.routes[9].children.splice(0, 1);
        },
        // 控制隐藏界面显隐
        addHideEnRoutesData() {
            sessionStorage.setItem('hideEnRouteStateData', 'open');
            let currentIndex = -1;
            for (let index = 0; index < this.routes.length; index++) {
                const element: any = this.routes[index];
                if (element.path === '/guard') {
                    currentIndex = index;
                }
            }
            if (currentIndex !== -1) {
                this.routes[currentIndex].children = this.routes[
                    currentIndex
                ].children.concat([
                    {
                        path: '/guard/people',
                        component: () =>
                            import('@/views/guard/people/index.vue'),
                        name: 'people',
                        meta: {
                            title: 'content.router.people',
                            roles: ['12010000']
                        }
                    },
                    {
                        path: '/guard/control-task',
                        component: () =>
                            import('@/views/guard/control-task/index.vue'),
                        name: 'controlTask',
                        meta: {
                            title: 'content.router.controlTask',
                            roles: ['12040000']
                        }
                    },
                    {
                        path: '/guard/control-data',
                        component: () =>
                            import('@/views/guard/control-data/index.vue'),
                        name: 'controlData',
                        meta: {
                            title: 'content.router.controlData',
                            roles: ['12050000']
                        }
                    }
                ]);
            }
            router.addRoute({
                path: '/guard',
                component: Layout,
                meta: {
                    title: 'content.router.guard',
                    icon: 'guard',
                    roles: [
                        '12000000',
                        '12010000',
                        '12020000',
                        '12030000',
                        '12040000',
                        '12050000'
                    ]
                },
                children: [
                    {
                        path: '/guard/people',
                        component: () =>
                            import('@/views/guard/people/index.vue'),
                        name: 'people',
                        meta: {
                            title: 'content.router.people',
                            roles: ['12010000']
                        }
                    },
                    {
                        path: '/guard/control-task',
                        component: () =>
                            import('@/views/guard/control-task/index.vue'),
                        name: 'controlTask',
                        meta: {
                            title: 'content.router.controlTask',
                            roles: ['12040000']
                        }
                    },
                    {
                        path: '/guard/control-data',
                        component: () =>
                            import('@/views/guard/control-data/index.vue'),
                        name: 'controlData',
                        meta: {
                            title: 'content.router.controlData',
                            roles: ['12050000']
                        }
                    }
                ]
            });
            router.push('/id-manage').then(() => {
                location.reload();
            });
        },
        hideEnRoutesData() {
            // const routes = ['controlData', 'controlTask', 'people'];
            // for (let i = 0; i < this.routes.length; i++) {
            //     if (this.routes[i].path === '/guard') {
            //         this.routes[i].children = this.routes[i].children.filter(
            //             (item) => !routes.includes(item.name)
            //         );
            //     }
            // }
            sessionStorage.setItem('hideEnRouteStateData', 'hide');
            router.push('/id-manage').then(() => {
                location.reload();
            });
        }
    }
});
