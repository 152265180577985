export default {
    content: {
        appTitle: 'SmartBox Management System',
        // request请求
        loading: 'loading',
        noAuth: 'You do not have permission, please login',

        // 通用
        general: {
            inputPlaceholder: 'Please enter your search',
            deleteTip: 'Are you sure you want to delete?',
            deleteSuccess: 'Delete success',
            addSuccess: 'Add success',
            editSuccess: 'Edit success',
            day: 'day',
            hour: 'hour',
            minute: 'minute',
            second: 'second',
            days: 'days',
            hours: 'hours',
            minutes: 'minutes',
            seconds: 'seconds'
        },
        // 路由
        router: {
            empty: '',
            login: 'Login',
            license: 'License',
            dataPanel: 'Data Panel',
            idManage: 'Channel Management',
            idConfig: 'Channel Config',
            guard: 'Intelligent',
            people: 'People Lib',
            licensePlate: 'Plate Lib',
            workCloth: 'Workcloth Lib',
            controlTask: 'Monitoring Task',
            controlData: 'Monitoring Data',
            analysis: 'Analysis',
            behavioralEvent: 'Behavioral Event',
            trafficEvent: 'Traffic Event',
            peopleCounting: 'People Counting',
            fullStructuredSnapshot: 'Full Structured Snapshot',
            search: 'Data Search',
            behaviorSearch: 'Data Search',
            eventsLog: 'Events Log',
            workClothSnapshot: 'Workcloth Snapshot',
            targetSearch: 'Target Search',
            PK: 'External Config',
            dataDock: 'Data Dock',
            system: 'System Config',
            userConfig: 'User Config',
            roleConfig: 'Role Config',
            defender: 'Defender',
            versionInfo: 'Version Info',
            operationLog: 'Operation Log',
            timeConfig: 'Time Config',
            operatorManage: 'Operator Manage',
            personalConfig: 'Personal Config',
            dataSend: 'Data Report',
            networkGrabbing: 'Packet Capture',
            securityConfig: 'Security Center',
            resourceList: 'Resource List',
            basicConfig: 'Basic Config',
            serviceList: 'Service List',
            apiStatus: 'Interface Status',
            networkConfig: 'Network Config',
            ipConfig: 'TCP/IP',
            npsConfig: 'NPS',
            planLinkage: 'Arming Linkage'
        },
        // navbar
        changeLang: {
            zh: 'Chinese',
            en: 'English',
            message: 'Change success',
            messageFail: 'Change failure'
        },
        changeTheme: 'Change Theme',
        navBar: {
            modifyPwd: 'Modify Password',
            logout: 'Logout',
            logoutTips: 'Are you sure to log out?'
        },
        // 按钮
        button: {
            add: 'Add',
            edit: 'Edit',
            delete: 'Delete',
            refresh: 'Refresh',
            close: 'Close',
            cancel: 'Cancel',
            confirm: 'Confirm',
            export: 'Export',
            save: 'Save',
            skip: 'Skip',
            search: 'Search',
            query: 'Query',
            reset: 'Reset',
            picQualityConfig: 'Picture Quality Config',
            open: 'OPen',
            new: 'New',
            autoSearch: 'Auto Search',
            batchDelete: 'Batch Delete',
            downloadTemplate: 'Download Template',
            batchImportChannel: 'Batch Import Channel',
            batchExportChannel: 'Batch Export Channel',
            config: 'Config',
            info: 'Info'
        },
        // 算法模式
        mode: {
            title: 'Algorithm Mode',
            modeFace: 'Face',
            modeBehavior: 'Behavior',
            changeError: 'Save failed',
            desc: 'Tip: Switching modes will clear the algorithm configuration'
        },
        logout: {
            button: 'Logout'
        },
        // 修改密码
        modifyPwd: {
            title: 'Modify Password',
            oldPassword: 'Old password',
            newPassword: 'New password',
            usernamePlaceholder: 'Please enter your username',
            confirmNewPassword: 'Confirm new password',
            oldPasswordPlaceholder: 'Please input old password',
            newPasswordPlaceholder: 'Please input new password',
            confirmNewPasswordPlaceholder: 'Please confirm old password',
            pwdStrength: 'Pwd strength',
            pwdStrengthFirst: 'Without',
            pwdStrengthSecond: 'Weak',
            pwdStrengthThird: 'Middle',
            pwdStrengthFourth: 'Strong',
            pwdNull: 'The password cannot be empty',
            pwdChinese: 'The password cannot has Chinese',
            pwdSpace: 'The password cannot has space',
            pwd620: 'The password contains 6 to 20 digits',
            confirmPwdEmpty: 'The confirm password cannot be empty',
            confirmPwdSame: 'The confirm password must as same as password',
            originPwdNull: 'The original password cannot be empty'
        },
        // 登录
        login: {
            welcome: 'Welcome',
            account: 'Account',
            pwd: 'Password',
            button: 'Login',
            messageSuccess: 'Login success',
            messageError: 'Login failed',
            accountPlaceholder: 'Please input account',
            passwordPlaceholder: 'Please input password',
            forgetPwd: 'Forget Password',
            confirmPwdPlaceholder: 'Please confirm password',
            confirmPwdValidator: 'The two passwords are inconsistent',
            register: 'Register',
            registerSuccess: 'Register success',
            authFail: 'Permission denied',
            usernameOrPwdIsEmpty: 'Account or password cannot be empty',
            noPwdProtect:
                'If the password protection is not set, the Forget password function cannot be used',
            question: 'Question',
            answer: 'Answer',
            newPwd: 'New password',
            confirmPwd: 'Confirm password',
            pwdResetSuccess: 'Password reset success',
            loginIng: 'Logging in',
            next: 'Next',
            confirmModify: 'Confirm Modify',
            pwdNoEmpty: 'The password cannot be empty',
            nameNoEmpty: 'The account cannot be empty',
            pwd620: 'The password contains 6 to 20 digits',
            confirmPwdNoEmpty: 'The confirm password cannot be empty',
            confirmPwdSame: 'The confirm password must as same as password',
            questionMsg: 'Please choose question',
            tryAgain: 'Please refresh and retry',
            verifyPermission: 'Please verify the permission password first',
            validateError: 'Cannot select the same question',
            verifySuccess: 'The permission password is successfully verified',
            verifyFailed: 'The permission password is falsely verified',
            loginError: 'The transport info is error',
            usernamePlaceholder: 'Please enter your username',
            timeout: 'Login timeout'
        },
        // license
        license: {
            header: 'Please complete the initial configuration based on the following prompts!',
            title: 'LicenseAuthorization',
            step1: 'If no hardware fingerprint file exists, apply for a hardware fingerprint file and then a certificate',
            step2: 'If a hardware fingerprint file has been applied for, upload the certificate file directly',
            generateFinger: 'Generate hardware fingerprint',
            chooseFile: 'Choose File',
            upload: 'Upload',
            uploadSuccess: 'Upload success',
            fileEmpty: 'Please choose file',
            uploadError: 'Upload failed',
            downloadSuccess: 'Download success',
            loading: 'loading...'
        },
        // 点位管理
        idManage: {
            title: 'Channel Management',
            channelName: 'Channel name',
            streamStatus: 'Stream status',
            online: 'Online',
            offline: 'Offline',
            ipAddress: 'Ip address',
            warnType: 'Alarm type',
            operate: 'Operation',
            noChannel: 'No channel',
            exportChannel: 'Export channel',
            exportType: 'Export type',
            exportOption1: 'Export all',
            exportOption2: 'Export this page',
            exportOption3: 'Export selected',
            chooseChannelTip: 'Please choose export channel',
            exportSuccess: 'Export success',
            chooseChannel: 'Please choose channel',
            addChannel: 'Add Channel',
            chooseBitStream: 'Bit stream',
            protocol: 'Protocol',
            protocolTips: 'Please choose protocol',
            id: 'Channel number',
            port: 'Port',
            user: 'Account',
            pwd: 'Password',
            // 新增点位
            encodingTips: 'The encoding format cannot be empty',
            encoding: 'Encoding format',
            transport: 'Transport protocol',
            transportTips: 'Please choose transport protocol',
            channelLimit: 'The channel is full',
            mode1: 'Authentication mode',
            mode2: 'NoAuthentication mode',
            deviceInfo: 'Device info',
            serialNumber: 'Serial number',
            company: 'Company',
            model: 'Model',
            stream1: 'Main stream',
            stream2: 'Sub stream',
            stream3: 'Third stream',
            encode: 'Encode',
            ratio: 'Ratio',
            rtsp: 'rtsp',
            fps: 'FPS',
            kbps: 'KBPS',
            quality: 'Quality',
            next: 'Next',
            back: 'Back',
            protocolErr: 'The protocol cannot be empty',
            transportErr: 'The transport type cannot be empty',
            usernameErr: 'The username cannot be empty',
            pwdErr: 'The password cannot be empty',
            nameErr1: 'The channel name cannot be empty',
            nameErr2: 'The channel name must not exceed 32 characters',
            ipErr1: 'The address cannot be empty',
            ipErr2: 'The address is incorrect',
            portErr1: 'The port cannot be empty',
            portErr2: 'The port can only be number',
            rtspErr: 'The rtsp cannot be empty',
            // 编辑点位
            editChannel: 'Edit Channel',
            // 自动搜索
            type: 'Type',
            ifAdd: 'If added',
            ifAdd1: 'Added',
            ifAdd2: 'No added',
            auto: 'Auto Search Channel',
            // 批量导入点位
            batchImportChannel: 'Batch Import Channel',
            upload: 'Upload',
            uploadTip:
                'Tip: please download the template or export channel, and fill in as required',
            uploadErr1: 'Please upload file',
            number: 'Number is',
            importErr: 'import failed',
            importSuccess: 'import success',
            // 点位详情
            channelInfo: 'Channel info',
            status: 'Status',
            // 点位配置
            syncChannel: 'Sync Channel',
            addTip: 'Add algorithm function',
            algoEmpty:
                'There is currently no algorithm content available,hurry up and add it~',
            saveVideo: 'Save Video',
            ifOpen: 'If open',
            beforeWarn: 'Before alarm',
            afterWarn: 'After alarm',
            warnSwitch: 'Alarm recovery switch',
            ruleConfig: 'Rule Config',
            guardConfig: 'Alarm Defense Time',
            linkageplan: 'Linkage Plan',
            chooseFunction: 'Choose Function',
            chooseAll: 'Choose All',
            syncTo: 'Sync To',
            addFunction: 'Add Function',
            warnErr1: 'The alarm time cannot be empty',
            warnErr2:
                'The sum of the time before and after the alarm must not exceed 60 seconds',
            warnErr3:
                'The time before or after the alarm must not be less than 5 seconds',
            saveErr1: 'Please save the algorithm configuration first',
            syncErr1:
                'Please finish operating the algorithm first before synchronizing the position',
            syncErr2: 'Please choose function',
            syncErr3: 'Please choose channel',
            syncSuccess: 'The position synchronization was successful',
            channel: 'Channel',
            syncComplete: 'The synchronization is complete',
            syncFail: 'The synchronization failed',
            addErr1:
                'Please finish operation the algorithm first before adding',
            removeSuccess: 'The removal was successful',
            addErr2: 'Please select the functional modules to be added',
            // 区域设置
            paintPolygon: 'Paint Polygon',
            paintLine: 'Paint Line',
            clearRule: 'Clear Rule',
            paintDes: 'Paint Description',
            des1: 'Left click to draw,  right click to end the drawing process',
            des2: 'When drawing a line, have at least 2 points',
            des3: 'When drawing a polygon, have at least 2 points and at most 6 points',
            des4: 'Trip wire count must click draw direction line again after draw the line',
            des5: 'Climbing detection must click draw height line again after draw the polygon',
            attr: 'Attribute',
            time: 'TriggerTime',
            person: 'Person',
            personLimit: 'Person limit',
            background: 'Background refresh time',
            second: 'Seconds',
            clock: "o'clock",
            personFloat: 'Person float',
            workCloth: 'Workcloth lib',
            workClothPlaceholder: 'Please choose workclothlib',
            paintErr1: 'The number of polygons and lines does not agree',
            paintErr2: 'Please choose workclothlib',
            paintErr3: 'The number of lines and directions does not agree',
            paintErr4: 'The number of polygons and lines does not agree',
            paintErr5: 'Please draw the area first',
            saveSuccess: 'Save success',
            saveFail: 'Save failed',
            syncRule: 'Sync Rule',
            toChannel: 'To Channel',
            // 布防时间
            copy: 'Copy To This Week',
            clearAll: 'Clear All',
            day1: 'Monday',
            day2: 'Tuesday',
            day3: 'Wednesday',
            day4: 'Thursday',
            day5: 'Friday',
            day6: 'SaturDay',
            day7: 'Sunday',
            chooseOneDay: 'Please choose one day',
            copyChannel: 'Copy Time To Channel',
            copyChannelPlaceholder: 'Please choose channel',
            //联动计划
            linkagePlanType1: 'Regular Linkage',
            linkagePlanType2: 'Linkage Alarm',
            eventType: 'Event type',
            algoTypePlaceholder: 'Please select an algorithm type',
            all: 'All',
            open: 'Open',
            //算力计算
            residualPowerTitle: 'The current computing power remains: ',
            residualPowerError:
                'The computing power is insufficient, please configure intelligent services properly',
            memResidualPowerTitle: 'The current graphics memory remains: ',
            close: 'close',
            faceCatch: 'Personnel Deployment Control',
            direction: 'Detection direction',
            detourarea: 'Detour area',
            detourareaTip: 'Please choose detour area config',
            line: 'Line',
            directionTip: 'Please choose detection direction',
            videoSave: 'Alarm video save',
            noPutEventTime: 'Trigger time of uncovered trash can',
            videoBefore: 'Video duration before an alarm is generated',
            videoAfter: 'Video duration after an alarm is generated',
            pupilDistance: 'Pupil distance',
            faceType: 'Face recognition mode',
            faceTypeTip: 'Please choose recognition mode',
            cycleTime: 'Cycle reporting time',
            fastTime: 'Fast reporting time',
            clear: 'Trip wire count cleared',
            clearTime: 'Cleared time',
            minWidth: 'Minimum head and shoulder frame size width',
            height: 'Height',
            maxWidth: 'Maximum head and shoulder frame size width',
            warnTime: 'Trigger time',
            occupyTime: 'Trigger time of vehicle illegal parking',
            leaveTime: 'Trigger time of leave post',
            leavePeople: 'Minimum person limit of leave post(1~39)',
            sleepTime: 'Trigger time of sleep',
            entryOccupyTime: 'Trigger time of blocked escape route',
            randoOccupyTime: 'Trigger time of material litttering',
            fallTime: 'Trigger time of fall(3~1800)',
            phoneTime: 'Trigger time of play phone',
            flushTime: 'Background refresh time',
            reportrate: 'Alarm interval',
            sensitivity: 'sensitivity',
            goodsMoveTime: 'Trigger time of goods missing ',
            numLimit: 'The number of personnel exceeds the limit',
            numTime: 'The time of personnel exceeds the limit',
            loiterTime: 'The time of stay in the area',
            gatherTime: 'Trigger time of gather',
            gatherLimit: 'Person limit of gather',
            timeNumLimit: 'Maximum number of new users within the trigger time',
            vehicleTime: 'The time of vehicle stopping',
            modeOption1: 'Effect first',
            modeOption2: 'Fast mode',
            modeOption3: 'Cycle mode',
            modeOption4: 'Report in advance',
            enterinduration: 'Trigger time for regional entry'
        },
        //Workcloth lib
        workCloth: {
            empty: 'No data',
            deleteLibPlacholder:
                'Delete the work cloth lib will delete the data in lib simultaneously, please confirm',
            total: 'Total ：  ',
            libCount: 'libs',
            workClothInLib: 'Workcloth Lib',
            modelingStatus: 'Model status',
            modelingStatusPlaceholder: 'Please choose model status',
            selectAllInPage: 'Choose this page',
            deletePlaceholder: 'Are you sure delete?',
            autoImport: 'Auto import',
            batchImport: 'Batch import',
            exportWorkCloth: 'Export',
            modeling: 'Modeling',
            modelingSuccess: 'Model success',
            modelingFailed: 'Model failed',
            emptyWorkCloth:
                'The lib is temporarily out of work clothes, hurry to add',
            strip: '',
            addLib: 'Add work cloth lib',
            libName: 'Lib name',
            libNamePlaceholder: 'Please input lib name',
            editLib: 'Edit work cloth lib',
            exportData: 'Export data',
            exportType: 'Export type',
            autoImportWorkCloth: 'Auto import work cloth',
            pointSelection: 'Channel choose',
            pointSelectionPlaceholder: 'Please choose channel',
            startAnalysis: 'Start analysis',
            selectAll: 'Choose all',
            identifyRate: 'Identify rate',
            identify: 'Total identify',
            pictures: '',
            import: 'Import',
            analysisAgain: 'Retry analysis',
            importAndAnalysis: 'Import selected and retry analysis',
            libNameEmpty: 'Work cloth lib name cannot be empty',
            libNameError:
                'Please input correct lib name(cannot be empty, length<32, must be number, letter or Chinese )',
            addSuccess: 'Add success',
            maxLength: 'Up to 12 bits',
            libNameValidError:
                'Please input correct name(cannot be empty, length<100, must be number, letter or Chinese )',
            editSuccess: 'Edit success',
            deleteSuccess: 'Delete success',
            all: 'All',
            deleteWorkClothMsg: 'Please choose work cloth to delete',
            lower: 'low',
            generally: 'normal',
            higher: 'high',
            exportAll: 'Export all',
            exportPage: 'Export this page',
            exportSelect: 'Export selected',
            clothEmpty:
                'The current page data is empty,so it cannot be exported',
            selectWorkClothEmptyPlaceholder:
                'Please choose work cloth to export',
            exportSuccess: 'Export success',
            channelEmptyPlaceholder: 'Please choose channel',
            channelLimitPlaceholder: 'Please choose channel at most 5',
            importSuccess: 'Import success',
            importPlaceholder: 'Please choose channel to import',
            manuallyImport: 'Import manually',
            workClothPhoto: 'picture',
            workClothPhotoInfo: 'Tip: picture size < 1920px * 1080px, < 5M',
            workClothPhotoLimit: 'Picture > 5M',
            workClothPhotoLimit1: 'The current type of image is not supported',
            workClothPhotoError: 'Picture cannot be empty',
            importWorkCloth: 'Import work cloth',
            importWorkClothInfo: 'Tip：Only zip files are supported',
            importWorkClothPlaceholder: 'Please choose import file',
            importWorkClothError: 'Part import failed',
            importWorkClothLimit: 'File must be < 800M',
            searchPlaceholder: 'Please enter the input',
            analysisAgainMsg: 'Please start analysis',
            libidEmpty: 'Please create lib first'
        },
        //人员库
        people: {
            blacklist: 'Black list',
            libNamePlaceholder: 'Please enter the input',
            deleteInfo:
                'Deleting the people lib will delete the people data simultaneously, confirm the deletion?',
            empty: 'No data',
            total: 'Total ：  ',
            libCount: 'libs',
            whitelist: 'White list',
            libStaff: 'People Lib',
            staffNamePlaceholder: 'Please input name',
            certificateNoPlaceholder: 'Please input certificate number',
            telPlaceholder: 'Please input phone number',
            emailPlaceholder: 'Please input email',
            sex: 'Sex',
            sexPlaceholder: 'Please choose sex',
            modelStatus: 'Model',
            modelStatusPlaceholder: 'Please choose model status',
            selectAllInPage: 'Choose this page',
            deleteConfirm: 'Are you sure you want to delete',
            batchImport: 'Batch Import',
            exportStaff: 'Export people',
            modeling: 'Modeling',
            modelingSuccess: 'Model success',
            modelingFailed: 'Model failed',
            name: 'Name',
            certificateNo: 'Certificate number',
            check: 'View',
            peopleEmpty: 'The lib is temporarily no people, hurry to add',
            strip: '',
            editPeopleLib: 'Edit People Lib',
            libName: 'People lib name',
            libNameSelectPlaceholder: 'Please choose people lib',
            libNameError: 'The people lib name cannot be empty',
            peopleLibNamePlaceholder: 'Please input people lib name',
            addLib: 'Add Lib',
            list: 'White or black list',
            listPlaceholder: 'Please choose list',
            exportData: 'Export data',
            exportType: 'Export type',
            nameEmpty: 'The name cannot be empty',
            nameError:
                'Please input correct name(cannot be empty, length<20, must be number, letter or Chinese )',
            peopleLibEmpty: 'Please add people lib first',
            addSuccess: 'Add success',
            nameMaxLength: 'Up to 10 bits',
            libMaxLengthError:
                'Please input correct name(cannot be empty, length<32, must be number, letter or Chinese )',
            nameMaxLengthError:
                'Please input correct name(cannot be empty, length<20, must be number, letter or Chinese )',
            editSuccess: 'Edit success',
            deleteSuccess: 'Delete success',
            all: 'All',
            male: 'Man',
            female: 'Woman',
            unknown: 'Unknown',
            unspecified: 'Unspecified',
            exportAll: 'Export all',
            exportPage: 'Export this page',
            exportSelect: 'Export selected',
            peopleIsEmpty:
                'The current page data is empty,so it cannot be exported',
            exportPeoplePlaceholder: 'Please choose people to export',
            exportSuccess: 'Export success',
            deletePlaceholder: 'Please choose people to delete',
            addStaff: 'Add People',
            libType: 'People lib type',
            libTypePlaceholder: 'Please choose people lib type',
            other: 'Other',
            facePhoto: 'Face photo',
            facePhotoLimit: 'tip: picture < 8192px*4320px and < 5M',
            peopleName: 'People name',
            peopleNamePlaceholder: 'Please input people name',
            peopleSex: 'People sex',
            peopleSexPlaceholder: 'Please choose people sex',
            certificateType: 'Certificate type',
            certificateTypePlaceholder: 'Please choose certificate type',
            IDNumber: 'Certificate number',
            IDNumberPlaceholder: 'Please input certificate number',
            tel: 'Phone number',
            email: 'Email',
            IDCard1: 'Id',
            IDCard2: 'Passport',
            IDCard3: 'Driving license',
            IDCard999: 'Other',
            passport: 'Passport',
            drvingLicense: 'Driving license',
            facePhotoError: 'Picture > 5M',
            nonCompliant1: 'Not meet the requirements',
            nonCompliant2: 'The email does not fit the format',
            nonCompliant3: 'The phone number does not fit the format',
            nonCompliant4: 'The picture must not be empty',
            nonCompliant5: 'The sex must not be empty',
            nonCompliant6: 'The certificate type must not be empty',
            checkStaff: 'View people',
            editStaff: 'Edit people',
            importStaff: 'Import people',
            fileParsing: 'File parsing...',
            fileParsingInfo:
                'Tip: please download first and import files as required by the template, file name must be faceTemplate.zip',
            import: 'Import',
            fileUploading: 'File uploading...',
            importPlaceholder: 'Please choose file to import',
            fileSizeLimit: 'The maximum file size is 800M',
            fileError: 'The part import failed',
            importSuccess: 'Import success',
            download: 'Download template',
            fileNameErr: 'Please input correct file name',
            libidEmpty: 'Please add people lib first',
            birthday: 'Date of birth',
            birthdayPlaceholder: 'Please choose Date of birth'
        },
        //布控任务
        controlTask: {
            controlTask: 'Monitoring Task',
            personnelControl: 'People control',
            vehicleControl: 'Vehicle control',
            deleteContent: 'Are you sure you want to delete?',
            controlList: 'Control list',
            controListPlaceholder: 'Please choose control list',
            controListEmpty: 'The control list cannot be empty',
            libType1: 'White list',
            libType2: 'Black list',
            libType3: 'Not set',
            controlLib: 'Control lib',
            controlChannel: 'Control channel',
            controlChannelPlaceholder: 'Please choose channel',
            controlChannelEmpty: 'The control channel cannot be empty',
            alarmThreshold: 'Alarm threshold',
            alarmThresholdPlaceholder: 'Please input alarm threshold(0-100)',
            alarmThresholdEmpty: 'The alarm threshold cannot be empty',
            alarmStatus: 'Alarm threshold',
            controlling: 'Controlling',
            controlPause: 'Control pause',
            controlEnd: 'Control end',
            controlDate: 'Control date',
            controlDateEmpty: 'The control date cannot be empty',
            controlDateError:
                'The end time cannot be earlier than the start time',
            controlDateEnd: '——to——',
            controlStartDatePlaceholder: 'Please choose start time',
            controlEndDatePlaceholder:
                'Please choose end time, otherwise it works forever',
            forever: 'Forever',
            operation: 'Operation',
            check: 'View',
            pause: 'Pause',
            start: 'Start',
            controlLimt: 'The task is full and cannot be added',
            deleteSuccess: 'Delete success',
            deleteControlInfo: 'Please choose task to delete',
            pauseSuccess: 'Pause success',
            startSuccess: 'Start success',
            addStaffTask: 'Add people control',
            staffLib: 'People lib',
            staffLibPlaceholder: 'Please choose people lib',
            staffLibEmpty: 'The people lib cannot be empty',
            controlDescription: 'Control reason',
            controlDescriptionPlaceholder:
                'Please input correct control reason',
            controlDescriptionEmpty: 'The control reason cannot be empty',
            snapStatusEmpty:
                'Whether the matched person shows that the capture could not succeed',
            addControlTaskSuccess: 'Add success',
            channel: 'Channel',
            addSuccess: 'Add success',
            controlFaild: 'Control failed',
            checkStaffTask: 'View people control',
            snapStatus: 'Whether the matched person shows the captuer',
            yes: 'Yes',
            no: 'No',
            editStaffTask: 'Edit people control',
            editSuccess: 'Edit success',
            controlName: 'Task name',
            controlNamePlaceholder: 'Please input task name',
            controlNameError: 'The task name cannot be empty',
            controlType: 'Control type',
            controlTypePlaceholder: 'Please choose control type',
            controlTypeError: 'The control type cannot be empty',
            controlLibName: 'Control lib name',
            enable: 'Enable',
            controlLibType: 'Control lib type',
            all: 'All',
            compareSuccess: 'Compare success',
            compareFail: 'Compare failed',
            open: 'Open',
            close: 'Close'
        },
        //布控数据
        controlData: {
            controlData: 'Monitoring Data',
            channelNoEmpty: 'The search channel cannot be empty',
            personnelControl: 'Face compare',
            vehicleControl: 'Behavior face compare',
            channelPlacholder: 'Please choose channel',
            namePlaceholder: 'Please input name',
            certificateNoPlaceholder: 'Please input number',
            libType: 'List type',
            libTypePlaceholder: 'Please choose list type',
            snapStatus: 'Compare',
            snapStatusPlaceholder: 'Please choose if compare',
            similarity: 'Similarity',
            compareSymbolPlaceholder: 'Please select greater than or less',
            similarityPlaceholder: 'Please input similarity',
            selectAllInPage: 'Choose this page',
            deleteContent: 'Are you sure you want to delete?',
            notMatched: 'Not matched',
            matched: 'Matched',
            empty: 'No data~',
            total: 'Total ：  ',
            strip: '',
            searchPlaceholder: 'Please enter the input',
            exportData: 'Export data',
            exportType: 'Export type',
            exportLimit: 'Tip: The maximum number of exported files is 1000',
            all: 'All',
            blacklist: 'Black list',
            whitelist: 'White list',
            exportAll: 'Export all',
            exportPage: 'Export this page',
            exportSelect: 'Export selected',
            exportPlaceholder: 'Please choose data to export',
            exportPlaceholderVehicle: 'Please choose vehicle to export',
            exportSuccess: 'Export success',
            deleteControlInfo: 'Please choose control info to delete',
            deleteSuccess: 'Delete success',
            check: 'View',
            channelName: 'Channel',
            timeStamp: 'Alarm time',
            quickEditorOption4: 'Undisposed',
            quickEditorOption1: 'Report correctly',
            quickEditorOption2: 'Report incorrectly',
            quickEditorOption3: 'Nonconcerned',
            gender: 'Sex',
            age: 'Age',
            glass: 'Glass',
            mask: 'Mask',
            coatcolor: 'Coat color: ',
            umbrellacolor: 'Umbrella or not ',
            bagstyle: 'Bag or not: ',
            capstyle: 'Hat or not: ',
            vehicleclass: 'Vehicle type: ',
            vehiclecolor: 'Vehicle color: ',
            platecolor: 'Plate color: ',
            plateno: 'Plate number: ',
            nonmotormaincolor: 'Main color of vehicle: ',
            passengers: 'Number of passengers',
            helmet: 'Helmet or not: ',
            wearHelmet: 'Helmet or not: ',
            unknown: 'Unknown',
            compareSuccess: 'Compare success',
            comparimFail: 'Compare failed',
            male: 'Man',
            female: 'Woman',
            child: 'Children',
            juvenile: 'Juvenile',
            youth: 'Teenager',
            middleAged: 'Middle age',
            elderly: 'Old',
            no: 'No',
            wear: 'Wear'
        },
        // Behavior event
        behavioralEvent: {
            eventHappened: 'Event happened',
            eventHappenedTotal: 'Event happened total',
            behavioralEventNum: 'Behavior event total',
            leaderboard: 'Ranking list',
            noData: 'No data',
            eventHande: 'Event handle',
            unit: 'piece',
            eventHandeTotal: 'Event handle total',
            behavioralEvent: 'Behavior event',
            time: 'Time',
            eventNum: 'Event num',
            today: 'Today',
            last7Days: 'Nearly 7 days',
            last30Days: 'Nearly 30 days',
            mostPedestrians: 'The most pedestrians',
            chooseDirection: 'Please choose direction',
            chooseChannel: 'Please choose channel',
            chooseQueryDate: 'Please choose date',
            timeNoSame: 'The start and end times cannot be the same'
        },
        // 算法种类
        algorithmType: {
            SafetyHelmetDetect: 'Safety helmet detection',
            SmokingDetect: 'Smoking detection',
            StrenousExercise: 'Fight detection',
            CallingDetect: 'Call detection',
            GoodsOccupy: 'Blocked escape route detection',
            FallingDetect: 'Human Fall detection',
            FireDetect: 'Fire detection',
            SmokeDetect: 'Smoke detection'
        },
        // 人数统计
        peopleStatistics: {
            peopleStatistics: 'People statistics',
            chooseChannel: 'Please choose channel',
            chooseStatisticType: 'Please choose statistic type',
            tripWiresNum: 'Trip wires num',
            regionalPeopleNum: 'Regional people num',
            today: 'Today',
            last7Days: 'Nearly 7 days',
            last30Days: 'Nearly 30 days',
            chooseQueryDate: 'Please choose date',
            timeNoSame: 'The start and end times cannot be the same',
            tripwireEntry: 'Trip wire entry',
            tripwireAway: 'Trip wire away',
            regionalStatistics: 'Regional statistics'
        },
        // 数据检索
        dataRetrieval: {
            behavioralEvent: 'Behavior event',
            chooseChannel: 'Please choose channel',
            chooseAlarmType: 'Please choose alarm type',
            chooseSearchType: 'Please choose search type',
            chooseProcessResult: 'Please choose handle result',
            namePlaceholder: 'Please input name',
            certificateNoPlaceholder: 'Please input number',
            chooseSearchModel: 'Please choose search mode',
            selectCurPage: 'Choose This Page',
            importToStaff: 'Import to the people lib',
            importToCloth: 'Import to work clothes warehouse',
            bigPic: 'Big picture',
            list: 'List',
            check: 'View',
            edit: 'Edit',
            video: 'Video',
            download: 'Download',
            noReletedData: 'No data',
            picture: 'Picture',
            channel: 'Channel',
            alarmType: 'Alarm type',
            dealResult: 'handle result',
            alarmTime: 'Alarm time',
            operate: 'Operation',
            total: 'Total ：  ',
            pieces: '',
            uniform: 'Workcloth lib',
            chooseUniform: 'Please choose workcloth lib',
            clothErr: 'Failed to obtain the work clothes library',
            exportData: 'Export data',
            exportType: 'Export type',
            alarmPicture: 'Alarm Picture',
            alarmVideo: 'Alarm Video',
            exportTip: 'Tip: The maximum number of exported files is 1000',
            dealResultEdit: 'Edit Handle Result',
            chooseOperateData: 'Please choose data to operate',
            chooseOperateDataError: 'Only one data can be imported',
            chooseClothError:
                'Please select the test data for not wearing work clothes',
            importSuccess: 'Import success',
            importFail: 'Import failed',
            exportAll: 'Export all',
            exportCurPage: 'Export this page',
            exportSelect: 'Export selected',
            noExport: 'Do not export',
            chooseExportEvent: 'Please choose event to export',
            peopleIsEmpty: 'Cannot export when the data on this page is empty',
            exportSuccess: 'Export success',
            noDeal: 'Undisposed',
            Truepositive: 'Report correctly',
            FalsePositive: 'Report incorrectly',
            nonConcern: 'Nonconcerned',
            editSuccess: 'Edit success',
            channelEmpty: 'The channel cannot be empty',
            dealResultEmpty: 'The handle result cannot be empty',
            alarmTypeEmpty: 'The alarm type cannot be empty',
            filterTimeEmpty: 'The time cannot be empty',
            chooseDeleteData: 'Please choose options to delete',
            deleteSuccess: 'Delete success',
            downloadSuccess: 'Download success',
            warnSearch: 'Behavior event search',
            takeSearch: 'Face take search'
        },
        // 查看行为事件弹框
        checkBehavior: {
            check: 'View',
            belongingPoint: 'Channel: ',
            alarmTime: 'Alarm time: ',
            alarmType: 'Alarm type: ',
            dealResult: 'Handle result: ',
            numberPlate: 'Plate number: ',
            noDeal: 'Undisposed',
            Truepositive: 'Report correctly',
            FalsePositive: 'Report incorrectly',
            nonConcern: 'Nonconcerned',
            gender: 'Sex: ',
            age: 'Age: ',
            glass: 'Glass: ',
            mask: 'Mask: ',
            coatcolor: 'Coat color: ',
            umbrellacolor: 'Umbrella or not ',
            bagstyle: 'Bag or not: ',
            capstyle: 'Hat or not: ',
            vehicleclass: 'Vehicle type: ',
            vehiclecolor: 'Vehicle color: ',
            platecolor: 'Plate color: ',
            plateno: 'Plate number: ',
            nonmotormaincolor: 'Main color of vehicle: ',
            passengers: 'Passengers: ',
            helmet: 'Helmet or not',
            wearHelmet: 'Helmeted',
            unknown: 'Unknown',
            all: 'All',
            compareSuccess: 'Compare success',
            comparimFail: 'Compare failed',
            male: 'Man',
            female: 'Woman',
            child: 'Children',
            juvenile: 'Juvenile',
            youth: 'Teenager',
            middleAged: 'Middle age',
            elderly: 'Old',
            no: 'No',
            wear: 'Wear',
            black: 'Black',
            white: 'White',
            grey: 'Gray',
            red: 'Red',
            blue: 'Blue',
            yellow: 'Yellow',
            orange: 'Orange',
            brown: 'Brown',
            green: 'Green',
            purple: 'Purple',
            cyan: 'Cyan',
            pink: 'Pink',
            silver: 'Silver',
            yellowGrenn: 'Yellow and green',
            gradientGreen: 'Gradient green',
            umbrella: 'Umbrella',
            bag: 'Bag',
            hat: 'Hat',
            bus: 'Bus',
            truck: 'Truck',
            car: 'Car',
            suv: 'Suv',
            van: 'Van',
            smallTruck: 'Small truck',
            bussinessCar: 'Business car',
            mediumBus: 'Medium bus',
            pickupTruck: 'Pickup truck',
            muckTruck: 'Muck truck',
            specialVehicle: 'Special vehicle',
            twoRounds: 'Two rounds',
            threeRounds: 'Three rounds',
            onePerson: 'One people',
            twoPerson: 'Two people',
            threePerson: 'Three people'
        },
        // 编辑行为事件弹框
        editBehavior: {
            edit: 'Edit',
            belongingPoint: 'Channel: ',
            alarmType: 'Alarm type: ',
            alarmTime: 'Alarm time: ',
            dealResult: 'Handle result',
            noDeal: 'Undisposed',
            Truepositive: 'Report correctly',
            FalsePositive: 'Report incorrectly',
            nonConcern: 'Nonconcerned',
            editSuccess: 'Edit success'
        },
        // 行为事件视频弹框
        checkVideo: {
            video: 'Video',
            noVideo: 'No video',
            belongingPoint: 'Channel: ',
            alarmType: 'Alarm type: ',
            alarmTime: 'Alarm time: ',
            dealResult: 'Handle result',
            noDeal: 'Undisposed',
            Truepositive: 'Report correctly',
            FalsePositive: 'Report incorrectly',
            nonConcern: 'Nonconcerned'
        },
        // 数据对接
        dataDock: {
            dataDock: 'Data Dock',
            uploadIntervalConfig: 'Upload interval config',
            channelName: 'Channel info',
            uploadInterval: 'Upload interval(min)',
            timeTip: 'Integer(1~1800)',
            openReport: 'Open report',
            enable: 'Sound Column Enable',
            audioEnable: 'Audio Enable',
            platformConfig: 'Platform config',
            HttpModel: 'HTTP/HTTPS mode',
            liveModel: 'HTTP/HTTPS live',
            isOpen: 'If open',
            open: 'Open',
            close: 'Close',
            pushAddress: 'Push address',
            netDetect: 'Network detection',
            liveTime: 'Live time',
            uploadPicData: 'Upload picture data',
            wsModel: 'WebSocket mode',
            saveSuccess: 'Save success',
            audioTime: 'Audio times',
            MQTT: 'MQTT config',
            MQTTModel: 'MQTT mode',
            audio: 'Audio config',
            report: 'Multi-target report mode',
            channelinfo: 'Channel status',
            video: 'Upload video data',
            pubswitch: 'AP100 switch',
            operswitch: 'Operation and maintenance platform switch',
            priswitch: 'AP1000 switch',
            audioIp: 'Audio IP',
            audioPlayTime: 'Audio play times',
            audioVol: 'Audio volume',
            eth: 'Device network card',
            state: 'Audio status',
            noLiveTime: 'The live time cannot be empty',
            noIntervalTime: 'The interval time cannot be empty',
            pushVideoAddress: 'Video push address',
            plzAudioIp: 'Please input audio IP',
            test: 'Report the test',
            face: 'Face',
            ba: 'Behavior',
            selectType: 'Select the type of report',
            videoInfo: 'video',
            ssForm: 'Access policy configuration'
        },
        // 数据看板
        dataPanel: {
            oneScreens: 'Change one screen',
            fourScreens: 'Change four screens',
            peopleCount: 'People count',
            areaPeople: 'Area people count',
            enterPeopleCount: 'Number of trip wire entries',
            leavePeopleCount: 'Number of trip wire leaving',
            fullScreen: 'Full screen',
            replaceVideo: 'Change channel',
            alarmInfo: 'Alarm info',
            mismatch: 'Mismatch',
            similarity: 'Similarity: ',
            allVideo: 'All channel',
            clearPeople: 'Clear count',
            useMse: 'MSE',
            channel: 'Channel',
            alarmType1: 'Do not wear workcloth detection',
            alarmType2: 'wear workcloth detection',
            debug: 'debugger'
        },
        // 用户配置
        userConfig: {
            title: 'User Config',
            setPwdProtect: 'Set Password Protect',
            user: 'Name',
            role: 'Role',
            operate: 'Operation',
            resetPwd: 'Reset Password',
            validatePwd: 'Validate Password',
            originPwd: 'Origin password',
            originPwdPlaceholder: 'Please input origin password',
            pwd: 'Password',
            confirmPwd: 'Confirm password',
            pwdPlaceholder: 'Please input password',
            validateSuccess: 'Validate success',
            validateFail: 'Validate failed',
            userNameNotEmpty: 'The name cannot be empty',
            roleNotEmpty: 'The role cannot be empty',
            addUser: 'Add User',
            question_1: 'Question1',
            question_2: 'Question2',
            question_3: 'Question3',
            answer_1: 'Answer1',
            answerPlaceholder: 'The question cannot be empty',
            answer_2: 'Answer2',
            answer_3: 'Answer3',
            settingSuccess: 'Set success',
            validNameError:
                'Please input correct name(cannot be empty, length<20, must be number, letter or Chinese )',
            validPwdError:
                'Please input correct name(cannot be empty, length 6-20, cannot be Chinese or empty )',
            originPwdError: 'Please input correct origin password',
            questionNull: 'Please set question first',
            operation: 'Operator',
            admin: 'Admin',
            editSuccess: 'Edit success, login again',
            passwordEmpty: 'The password cannot be empty',
            eidtUser: 'Edit User'
        },
        // 角色配置
        roleConfig: {
            roleSetting: 'Role Config',
            roleName: 'Role name',
            rolePermission: 'Role permission',
            permission: 'Auth',
            addRole: 'Add Role',
            editRole: 'Edit Role'
        },
        // 维护
        defenderConfig: {
            updateVersion: 'Version update',
            fileTip: 'File parsing...',
            update: 'Upgrade',
            completeUpdate: 'Completely Upgrade',
            deviceReset: 'Device restore',
            saveUserConfig: 'Save user config',
            reset: 'Restore',
            deviceReboot: 'Device restart',
            resetTip: 'Please wait patiently for a long time',
            rebootTip: 'Please wait patiently for a long time',
            reboot: 'Restart',
            settingsInfo: 'Config info',
            settingTip:
                'Importing a configuration deletes data such as channel and control, please operate cautiously',
            import: 'Import',
            export: 'Export',
            testInfo: 'Diagnosis info',
            exportTip: 'Please wait patiently for a long time',
            timeReboot: 'Restart regularly',
            coverSetting: 'Cover config',
            stay: 'Stay',
            dayData: 'Day data',
            wanData: 'Ten thousand data',
            waitReboot: 'It is restarting...',
            choseFileTip: 'Please choose file to import',
            choseFileTip1: 'Please turn off the upgrade remote enable switch',
            fileUploadSuccess: 'Upload success',
            uploadFailed: 'Upgrade failed',
            sending: 'Transporting',
            uploading: 'Upgrading',
            uploadSuccess: 'Upgrade success',
            uploadStatus: 'Upgrading',
            deviceResetSuccess: 'Device restore success',
            deviceResetFail: 'Device restore failed',
            rebootSuccess: 'Restart success',
            rebootFail: 'Restart failed',
            partImportFailed: 'Part import failed',
            importSuccess: 'Import success',
            exportSuccess: 'Export success',
            choseFile: 'Please choose file to import',
            selectTimeTip: 'Please choose time',
            timeToStartSet: 'Restart regularly set successfully',
            saveSuccess: 'Save success',
            remoteUpgrade: 'Remote upgrade',
            reLogin1: 'Upgrade successfully, please login wait a moment',
            reLogin2:
                'Upgrade successfully and IP has changed please login wait a moment',
            ipErr: 'Please input IP first',
            port: 'Port',
            portErr: 'Please input Port first',
            rebootTipMsg: 'Do you want to restart?',
            resetTipMsg: 'Do you want to restore?',
            net: 'NetWork Config',
            save: 'retain',
            noSave: 'no retain'
        },
        // 版本信息
        versionInfo: {
            versionInfo: 'Version Info',
            softVersion: 'Software version',
            macAddress: 'MAC address',
            runtime: 'Running time',
            name: 'Device name',
            namePlaceholder: 'Please enter the device name',
            codePlaceholder: 'Please enter the device ID',
            code: 'Device ID',
            lan: 'Device language',
            model: 'Product number',
            serialnumber: 'Serial No.',
            firmwareversion: 'Software version',
            nameNull: 'The device name cannot be empty',
            codeNull: 'The device ID cannot be empty',
            nullError: 'Please do not enter spaces or empty characters',
            lengthError:
                'Please enter characters that match the format(less than 32 characters)',
            successMsg: 'Modified data successfully'
        },
        networkConfig: {
            networkConfig: 'TCP/IP',
            baseInfo: 'Basic information',
            NIC: 'Default network card',
            plzNIC: 'Please select network card',
            ipGet: 'Obtain IP address',
            ip: 'IP address',
            ipType: 'IP Type',
            noIP: 'The IP address cannot be empty',
            mask: 'IP subnet mask',
            noMask: 'The IP subnet mask cannot be empty',
            getaway: 'IP Default Gateway',
            noGetaway: 'The IP Default Gateway cannot be empty',
            addressSet: 'Static/automatic address settings',
            static: 'Static setting',
            auto: 'Automatic acquisition',
            dns: 'DNS server',
            dns1: 'Automatically obtain DNS servers',
            dns2: 'Alternate DNS server',
            route: 'Default route',
            switch: 'Enable Switch',
            plzRoute: 'Please choose a default route',
            nps: 'NPS',
            serve: 'Server address',
            port: 'Server port',
            key: 'Secret key',
            name: 'Name',
            nicType: 'Network card type: ',
            NIC1: 'eth1',
            NIC2: 'eth2',
            noServer: 'The server address cannot be empty!',
            noPort: 'The server port cannot be empty!',
            error: 'Please submit content that meets the format requirements',
            netSet: 'Use this network interface settings: ',
            editSuc: 'Edit success',
            ipError: 'The server address is wrong',
            portError: 'The port is wrong',
            noKey: 'The key cannot be empty!',
            noIp: 'Please do not enter an empty IP'
        },
        // 操作日志
        operationLog: {
            log: 'Operation Log',
            selectModeTip: 'Please select module',
            selectSatusTip: 'Please select status',
            time: 'Time',
            operationUser: 'Operator',
            operationType: 'Category',
            remark: 'Remark',
            status: 'Status',
            success: 'Successfully',
            failed: 'Failed',
            all: 'All',
            userManage: 'User config',
            roleManage: 'Role config',
            sysManage: 'System config',
            algorithmManage: 'Operator config',
            dataPlatform: 'Data dock',
            smartRule: 'Configure intelligent analysis tasks',
            controlTime: 'Deployment time',
            logoConfig: 'Logo configuration',
            personControl: 'Personnel deployment',
            workCloth: 'Workwear library',
            pkMode: 'Expansion allocation',
            selectDateTip: 'please select the query date',
            timeNoSame: 'The start and end times cannot be the same'
        },
        // Operator management
        algorithmConfig: {
            cpuPercent: 'CPU usage:',
            ramPercent: 'Memory usage:',
            noData: 'No data available',
            usedPercent: 'Used',
            noneUse: 'Residual',
            chartsTarget: 'index',
            selectAlgo: 'Please select an operator',
            addAlgorithm: 'Add Operator',
            algoName: 'Operator name',
            IPAddress: 'IP address',
            port: 'Port',
            algorithmNameTip: 'The operator name cannot be empty',
            algoLengthTip: 'The operator name has a maximum of 15 characters',
            ipNoEmpty: 'IP address cannot be empty',
            ipNoSuite:
                'Please enter the conform to the format of the IP address',
            portNoEmpty: 'Please do not enter empty breaks',
            portNoSuite: 'Please enter the conform to the format of the port',
            editalgorithm: 'Editing Operator'
        },
        // 时间配置
        timeConfig: {
            timeSettings: 'Time Config',
            currentDate: 'Current time',
            setTime: 'Set time',
            handSetTime: 'Manual timing',
            dataAndTime: 'Date/Time',
            dataAndTimeTip: 'The date/time cannot be empty',
            setNTPServer: 'Set this parameter to the NTP service',
            syncTime: 'Synchronizing Computer Time',
            NTPsetTime: 'NTP proofreading time',
            serverAddress: 'Server address',
            networkTest: 'Net Detect',
            setTimeOut: 'Proofreading interval',
            serverTimeTip: 'The server check interval cannot beempty',
            minTip: 'Minutes',
            serverAddressTip: 'Please enter the correct server address',
            addressTip: 'The address cannot be empty',
            addressNoSuit: 'The address does not conform to the specification',
            port: 'NTP port',
            portTip: 'The NTP port cannot be empty',
            enable: 'Enable switch',
            yesterday: 'Yesterday',
            week: 'A week ago',
            now: 'Now',
            timeZone: 'Time Zone',
            TimeZone1: '(UTC-12:00) International Date Line West',
            TimeZone2: '(UTC-11:00) Coordinated Universal Time-11',
            TimeZone3: '(UTC-10:00) Hawaii, Aleutian Islands',
            TimeZone35: '(UTC-09:30) Marquesas Islands',
            TimeZone4: '(UTC-09:00) Alaska',
            TimeZone5: '(UTC-08:00) Pacific Standard Time(US & Canada)',
            TimeZone6: '(UTC-07:00) Mountain Standard Time(US & Canada)',
            TimeZone7: '(UTC-06:00) Central Standard Time(US & Canada)',
            TimeZone8: '(UTC-05:00) Eastern Standard Time(US & Canada)',
            TimeZone9: '(UTC-04:30) Caracas',
            TimeZone10: '(UTC-04:00) Atlantic Standard Time(Canada)',
            TimeZone11: '(UTC-03:30) Newfoundland',
            TimeZone12: '(UTC-03:00) Buenos Aires',
            TimeZone13: '(UTC-02:00) Coordinated Universal Time-02',
            TimeZone14: '(UTC-01:00) Cape Verde Islands, Azores Islands',
            TimeZone15: '(UTC+00:00) London, Dublin, Lisbon',
            TimeZone16: '(UTC+01:00) Berlin, Rome, Paris, Madrid, Warsaw',
            TimeZone17: '(UTC+02:00) Cairo, Jerusalem, Athens',
            TimeZone18: '(UTC+03:00) Moscow, Istanbul, Baghdad',
            TimeZone19: '(UTC+03:30) Teheran',
            TimeZone20: '(UTC+04:00) Abu Dhabi, Baku',
            TimeZone21: '(UTC+04:30) Kabul',
            TimeZone22:
                '(UTC+05:00) Islamabad, Karachi, Tashkent, Yekaterinburg',
            TimeZone23: '(UTC+05:30) Mumbai, New Delhi',
            TimeZone24: '(UTC+05:45) Kathmandu',
            TimeZone25: '(UTC+06:00) Dhaka, Omsk',
            TimeZone26: '(UTC+06:30) Yangon',
            TimeZone27: '(UTC+07:00) Jakarta, Bangkok, Hanoi',
            TimeZone28:
                '(UTC+08:00) Beijing, Hong Kong,Urumqi,Singapore, Taipei, Perth',
            TimeZone36: '(UTC+08:45) Eucla',
            TimeZone29: '(UTC+09:00) Tokyo, Osaka, Seoul, Yakutsk',
            TimeZone30: '(UTC+09:30) Adelaide, Darwin',
            TimeZone31: '(UTC+10:00) Melbourne, Sydney',
            TimeZone37: '(UTC+10:30) Lord Howe Island',
            TimeZone32: '(UTC+11:00) Solomon Islands, Magadan',
            TimeZone33: '(UTC+12:00) Wellington, Auckland',
            TimeZone38: '(UTC+12:45) Chatham Islands',
            TimeZone34: '(UTC+13:00) Nukulofa, Samoa Islands',
            TimeZone39: '(UTC+14:00) The Territory of Christmas Island',
            dst: 'DST',
            dstBias: 'DST Bias',
            startTime: 'Start time',
            endTime: 'End time',
            open: 'On',
            close: 'Off',
            january: 'Jan',
            february: 'Feb',
            march: 'Mar',
            april: 'Apr',
            may: 'May',
            june: 'Jun',
            july: 'Jul',
            august: 'Aug',
            september: 'Sep',
            october: 'Oct',
            november: 'Nov',
            december: 'Dec',
            monday: 'Mon',
            tuesday: 'Tue',
            wednesday: 'Wed',
            thursday: 'Thu',
            friday: 'Fri',
            saturday: 'Sat',
            sunday: 'Sun',
            first: 'First',
            second: 'Second',
            third: 'Third',
            fourth: 'Fourth',
            last: 'Last',
            hour: 'h',
            mins: 'mins'
        },
        // 个性化配置
        customConfig: {
            customTitle: 'Personal Config',
            logoAndName: 'Logo and name',
            systemTip1: 'Logo (small)',
            systemTip2: 'Logo',
            resetDefaultStyle: 'Restore default style',
            imgTip1:
                'The image resolution is recommended to be 40*40 or a multiple of 40*40',
            imgTip2:
                'The image resolution is recommended to be 160*60 or a multiple of 160*60',
            sysName1: 'Page title',
            sysName2: 'Login title',
            defaultName: 'Default name',
            customName: 'Custom name',
            customNameTip: 'Please enter a custom name',
            defaultNameTip: 'Default name is',
            AOXName: 'TIANXUAN Backstage Management System',
            aoxTip: ',support name customization,support mixed Chinese and English(Nno more than 34 Chinese characters or letters)',
            functionSetting: 'Function copy setup',
            functionTip:
                'Note:All functions of the system are displayed by default.If a function is turned off,this function will not be displayed in point management!',
            functionDefault: 'Function default name',
            showOrNot: 'Show or not',
            resetDefault: 'Restore Default',
            imgError: 'The image size exceeds 2MB.',
            logoTip: 'Logo image can be a jpg,jpeg,or png format',
            sysNameError: 'The system name cannot be empty',
            sysNameLengthTip:
                'The system name contains a maximum of 34 characters',
            sysNameStyleTip:
                'The system name does not conform to the standard,can only be letters',
            customNameLength:
                'The custom name contains a maximum of 15 characters',
            customNameEmpty: 'The custom name cannot be empty',
            noClose: 'The analysis task has been assigned and cannot be closed',
            audio: 'Audio File',
            videoUpload: 'Audio Upload',
            noImg: 'The image is empty',
            choseFileTip: 'Please select the file to import',
            logoTip1:
                'The image resolution is recommend to be 160*60 or multiple of 160*60',
            alarmtype: 'Alarm type'
        },
        // 隐藏界面的数据上报
        dataSend: {
            title: 'Behavioral Event',
            chooseChannel: 'Please select the channel',
            chooseWarnType: 'Please select the alarm type',
            chooseResult: 'Please select the processing result',
            choosePageAll: 'Select all on this page',
            ifOpen: 'Enable or not',
            send: 'Send',
            pic: 'Original images',
            list: 'List',
            noContent: 'No related content',
            picture: 'Picture',
            channel: 'channel',
            warnType: 'Alarm type',
            result: 'Processing result',
            warnTime: 'Alarm time',
            operation: 'Operation',
            total: 'Total ：  ',
            size: 'piece',
            importWork: 'Import To The Service Library',
            workCloth: 'Workwear library',
            workClothTip: 'Please select work clothes library',
            export: 'Export data',
            exportType: 'Export type',
            exportTip: 'Note:The maximum number of exported files is 1000',
            resultEdit: 'Processing Result Editing',
            saveSuccess: 'Save successfully',
            chooseData: 'Please select the data you want to manipulate',
            sendSuccess: 'Send successfully',
            importSuccess: 'Import successfully',
            export1: 'All Export',
            export2: 'Export this page',
            export3: 'Selected Export',
            chooseEvent: 'Please select export event',
            exportSuccess: 'Export successfully',
            type1: 'Report correctly',
            type2: 'Report mistakenly',
            type3: 'Nonattention',
            editSuccess: '"Modified successfully',
            type4: 'Untreated',
            searchErr1: 'The channel cannot be empty',
            searchErr2: 'The processing result cannot be empty',
            searchErr3: 'The alarm type cannot be empty',
            searchErr4: 'The filter time cannot be empty',
            deleteErr1: 'Please select the option you want to delete',
            deleteSuccess: 'Successfully deleted',
            downloadSuccess: 'Download succeeded',
            watch: 'View',
            belongChannel: 'Owning channel',
            plate: 'Plate no'
        },
        // 隐藏界面扩展配置
        pk: {
            title: 'External Config',
            sensitive: 'Sensitivity',
            interval: 'Report interval(s)',
            direction: 'Trigger direction',
            peopleNum: 'Number of alarms',
            background: 'Background refresh time',
            saveSuccess: 'Save successfully',
            second: '',
            people: 'people'
        },

        // 配置界面
        // 系统维护

        // 网络抓包
        networkGrab: {
            title: 'Packet Capture',
            networkChoose: 'Select NIC',
            networkPlaceholder: 'Please select network card',
            size: 'Packet size(byte)',
            sizetip:
                'If the packet size is 0, the complete packet will be captured without truncation processing',
            ip: 'IP address',
            hostType1: 'All',
            hostType2: 'Appoint',
            hostType3: 'Filter',
            port: 'Port',
            ipErr1: 'The IP address is incorrect',
            ipErr2: 'Please do not enter an empty IP',
            portErr1: 'The port is wrong',
            portErr2: 'Please do not enter empty breaks',
            sizeErr: 'The packet cannot be empty',
            success: 'Operation succeeded',
            warn: 'Please do not start grabbing bags until they are finished everywhere',
            stopGrab: 'Stop Grabbing',
            startGrab: 'Start Grabbing'
        },
        // 安全中心
        security: {
            title: 'Security Center',
            modifyPwd: 'ROOT Password Modification',
            fireConfig: 'Firewall Configuration Modification',
            oldPwd: 'Old password',
            newPwd: 'New password',
            pwdEmpty: 'The password cannot be empty',
            ip: 'Specify iP',
            net: 'Specify network segment',
            restore: 'Restore Default Configuration',
            pwdErr: 'Please enter a password that conforms to the format (within 6-40 characters, Chinese characters and spaces cannot be included)',
            ipErr: 'The IP address format is incorrect',
            netErr: 'Please enter the network segment',
            editRootMsg: 'Are you sure want to change your password?',
            fireEmpty: 'The configuration cannot be empty'
        },
        // 资源占用列表
        resourceList: {
            title: 'Resource List',
            number: 'Camera',
            name: 'Channel name',
            task: 'Current task'
        },
        // 基础配置
        basicConfig: {
            title: 'Basic configuration',
            version: 'Algorithm version'
        },
        serviceList: {
            title: 'Service List',
            name: 'Serve name',
            status: 'Status',
            online: 'Online',
            offline: 'Offline',
            pid: 'Course',
            version: 'Version',
            time: 'Start time',
            operate: 'Operation',
            getLog: 'Logs',
            log: 'Log information',
            moreInfo: 'Detailed information',
            getInfo: 'Get Details'
        },
        apiStatus: {
            title: 'Interface Status',
            name: 'Service name',
            url: 'Interface name',
            cnt: 'Total number of calls',
            day: 'Date',
            cnt1: 'Number of calls within 100ms',
            cnt2: 'Number of calls within 200ms',
            cnt3: 'Number of calls within 300ms',
            cnt4: 'Number of calls within 400ms',
            cnt5: 'Number of calls within 500ms',
            cnt6: 'Number of calls within 1000ms',
            cnt7: 'Number of calls more than 1000ms'
        },
        algType: {
            facedetection: 'Face Capture',
            safehatdetection: 'No Helmet Detection',
            workclothesdetection: 'No Work Clothes Detection',
            callingdetection: 'Calling Detection',
            smokingdetection: 'Smoking Detection',
            smokefiredetection: 'Smoke and Fire Detection',
            sleepdutydetection: 'Sleep On Duty Detection',
            absentdetection: 'Absence Detection',
            passagewayoccupy: 'Obstructed Evacuation Route Detection',
            goodsmoveddetetion: 'Lost Items',
            regionalinvasion: 'Intrusion Detection',
            elcforbid: 'Electric Vehicles Rrohibited',
            firetruckoccupy: 'Illegal Parking of Vehicles',
            falldetection: 'Human Fall Detection',
            pedestriananalysisregion: 'Area People Counting',
            areapersabndetection: 'People Exceed the Limit',
            pedestriancountingline: 'Tripwire People Counting',
            reflectclothesdetection: 'No Reflective Clothing Detection',
            quicklymoving: 'Quick Moving',
            maskdetection: 'No Mask Detection',
            climbingdetect: 'Climbing Detection',
            loiteringdetect: 'Long Stay Detection',
            persongathered: 'People Gathering',
            strenousexercise: 'Fight Detection',
            firedetect: 'Fire Detection',
            fumesdetection: 'Fume Detection',
            playphonedetect: 'Using Mobile Phone Detection',
            cookhatdetect: 'No Chef Hat Detection',
            mousedetect: 'Rat Detection',
            chefclothesdetect: 'No Chef Uniform Detection',
            shirtlessdetect: 'Shirtless Detection',
            trashcanfulldetect: 'Trash Bin Open Detection',
            coverlanddetect: 'Uncovered Bare Soil Detection',
            safetybeltdetect: 'No Safety Harness Detection',
            vehicledriveway: 'Unwashed Vehicle Detection',
            face: 'Face Service Configuration',
            fireemergency: 'Fire Emergency Configuration',
            safeproduction: 'Safety Production Configuration',
            chefbrightstove: 'Bright Kitchen and Stove Configuration',
            vas: 'Machine Not Human Configuration',
            noWearWorkCloth: 'No Work Clothes Detection',
            personCapture: 'Personnel Capture',
            personMatched: 'Personnel Matching',
            personNotRegistered: 'Person Not Registered',
            knifestickdetect: 'Hand-held Knife Adn Stick Detection',
            randomMaterials: 'Material Disorderly Piled Up',
            regionalenterin: 'Area Entry',
            regionalleavein: 'Area Departure',
            crosslinedetectin: 'Cross Line Detection'
        },
        alarmType: {
            noWearWorkCloth: 'No Work Clothes Detection',
            personCapture: 'Personnel Capture',
            personMatched: 'Personnel Matching',
            personNotRegistered: 'Person Not Registered',
            randomMaterials: 'Material Disorderly Piled Up'
        }
    },
    statusCode: {
        200: 'Operation succeeded',
        400: 'Request invalid',
        401: 'Insufficient permissions',
        403: 'No Access',
        404: 'Not Found',
        500: 'Internal Server Error',
        1000: 'Operation failed',
        1001: 'No file',
        1002: 'File suffix does not support',
        1003: 'Being upgraded',
        1004: 'In the compression log',
        1005: 'Pass the path error, please confirm',
        1006: 'The upgrade package does not exist, please confirm',
        1007: 'The upgrade package name is wrong, please confirm',
        1303: 'ZIP file name error',
        1304: 'No configuration file or wrong xml file name',
        1305: 'Version error, please select the correct version to upload',
        1306: 'Configuration file import failed',
        1307: 'ROOT failed to verify the old password',
        1500: 'This user cannot be deleted',
        1501: 'This username cannot be added',
        1502: 'This username cannot modify roles',
        1503: 'Username exceeds length limit',
        1504: 'Username contains special characters, please modify',
        1505: 'Username already exists, please modify',
        1506: 'Incorrect username or password.',
        1507: 'Password length must be greater than 6',
        1508: 'Password verification failed',
        1509: 'Security question not set',
        1510: 'User name does not exist,please confirm',
        1600: 'Channel name exceeds length limit',
        1701: 'Connection timed out',
        1702: 'Authentication failed',
        1703: 'IP address verification error',
        1704: 'Audio volume is out of range limit',
        1705: 'Audio playback times out of range limit',
        1706: 'NIC configuration error',
        1707: 'Enable switch configuration error',
        1708: 'Channel ID type error',
        // "180 "",
        1900: 'The IP address and the default gateway are not in the same network segment, please modify',
        1901: 'The subnet mask is invalid, please modify it',
        1902: 'Device name exceeds length limit',
        1903: 'The IP addresses of the two network cards are the same, please modify',
        1904: 'The network configuration has not changed, please confirm',
        2000: 'Algorithm not initialized',
        2001: 'Failed to start task',
        2002: 'Failed to stop the task',
        2003: 'Failed to switch algorithm',
        2004: 'Failed to get channel information',
        2005: 'The number of open channels has reached the maximum value',
        2006: 'Sensitivity parameter is wrong',
        2007: 'The reporting interval parameter is wrong',
        2008: 'The parameter of the trigger time for leaving the post is wrong',
        2009: 'Refresh duration is wrong',
        2010: 'Area intrusion warning direction parameter is wrong',
        2011: 'The maximum target size of the face is wrong',
        2012: 'The trigger time parameter of sleep post is wrong',
        2013: 'The alarm threshold is wrong',
        2014: 'The face minimum target size is wrong',
        2015: 'The algorithm is not destroyed and cannot be initialized',
        2016: 'The algorithm is not initialized and cannot be destroyed',
        2017: 'Algorithm initialization failed',
        2018: 'Algorithm destruction failed',
        2019: 'Current algorithm mode does not support',
        2031: 'Error accessing smart database',
        2032: 'The quantity does not reach 100000, no need to delete',
        2033: 'No folder under alarm/ba',
        2034: 'No characteristic value that can be delivered',
        2035: 'The number of regions exceeds the maximum',
        2036: 'Task does not support',
        2037: 'Area coordinate value out of range [0-10000]',
        2038: 'The number of area points exceeds the maximum=',
        2039: 'Wrong type of rule box',
        2040: 'Missing parameters',
        2041: 'Region coordinates clockwise',
        2042: 'Region coordinates counterclockwise',
        2043: 'The current task already exists',
        2044: 'The number of task rules delivered is empty',
        2045: 'The current mission cannot be started',
        2046: 'Unsupported Algorithm Mode',
        2047: 'Access people database error',
        2048: 'Library already exists',
        2049: 'The default list cannot be deleted',
        2051: 'The current algorithm has tasks enabled and cannot be switched',
        2052: 'Algorithm initialization, please wait',
        2053: 'The number of work clothes library exceeds the maximum value',
        2054: 'The number of overalls exceeds the maximum',
        2055: 'Library already exists',
        2056: 'The name is the same, please fill in again',
        2057: 'Workwear library does not exist, please confirm',
        2058: 'Work clothes ID does not exist, please confirm',
        2059: 'Please select a photo',
        2060: 'The maximum number of libraries has been reached',
        2061: 'The maximum number of people has been reached',
        2062: 'Library does not exist',
        2063: 'The feature value of the obtained image is wrong.',
        2064: 'The source eigenvalue has the wrong length',
        2065: 'Target eigenvalue has wrong length',
        2066: 'Please initialize the algorithm',
        2067: 'The algorithm is being released',
        2068: 'Sending feature values to the algorithm is successful, but modifying the database fails',
        2069: 'Modifying eigenvalues to the algorithm succeeded, but modifying the database failed.',
        2070: 'Failed to write person photo',
        2071: 'The database personnel table operation failed',
        2072: 'Import library in progress, please try again later.',
        2073: 'Exceeded the maximum number of libraries for a single import',
        2074: 'Modeling in progress, please wait',
        2075: 'The remaining storage capacity is insufficient to accommodate the current import quantity',
        2076: 'Algorithm switching, please try again later',
        2077: 'Argument length exceeds maximum value',
        2078: 'The face_id does not exist',
        2079: 'The current algorithm mode is not a safe production algorithm, please switch the algorithm mode',
        2080: 'Deploying the algorithm, please try again later',
        2081: 'During the intelligent analysis of work clothes snapshot, work clothes detection cannot be enabled',
        2082: 'The number of selected pictures exceeds the capacity limit of the uniform library (remaining capacity is {}), please re-select',
        20821: 'The number of selected pictures exceeds the capacity limit of the uniform library (remaining capacity is',
        20822: '), please re-select',
        2083: 'Parameter error, please confirm',
        2084: 'Parameter is out of range, please confirm',
        2085: 'The delivery parameter does not exist, please confirm',
        2086: 'Report type parameter error, please confirm',
        2087: 'Export data is empty, please confirm',
        2088: 'The number of plates reached the upper limit',
        2089: 'The length of the license plate number is illegal',
        2090: 'Database operation license plate information failed',
        2091: 'The license plate id does not exist',
        2092: 'This license plate number already exists in the library, please write again',
        2093: 'Task list is empty',
        2094: 'Task configuration in progress, please try again later',
        2095: 'The trigger time parameter for illegal parking is incorrect',
        2096: 'The trigger time parameter of item loss is wrong',
        2097: 'Smart tasks are mutually exclusive and cannot be enabled',
        2098: 'Wrong size parameter',
        2099: 'The area intrusion trigger parameter is incorrect',
        2301: 'There is no exportable data in the library',
        2302: 'The license plate number is illegal',
        2303: 'Template error, library import failed',
        2304: 'Person image cannot be empty',
        2305: 'The data is abnormal and the operation failed',
        2306: 'The smart task is using this uniform library, please cancel the task and delete it',
        2307: 'Modeling state does not exist',
        2200: 'The MQTT mode input format is wrong, please cancel the task and delete it',
        2201: 'Operation is prohibited when MQTT mode is enabled',
        2202: 'After MQTT is enabled, at least one MQTT mode is enabled',
        2203: 'The AP100/AP1000 platform only supports one mode',
        2204: 'After MQTT is enabled, at most one MQTT mode can be enabled',
        1800: 'Param check failed',
        2100: 'Channel blockage trigger parameter is wrong',
        2101: 'Fall trigger parameter is wrong',
        2102: 'The trigger time parameter of abnormal number of people is wrong',
        2103: 'The maximum number of people parameter is wrong',
        2104: 'Tripwire reset parameter is wrong',
        2105: 'Alarm save time parameter is wrong',
        2106: 'The computing power has exceeded the limit value',
        2107: 'The task is being sent, please try again later',
        2108: 'Workwear library feature is empty',
        2109: 'There are non-image files',
        2110: 'The image resolution exceeds 1920*1080',
        2111: 'The trigger time parameter for playing with a mobile phone is incorrect',
        2112: 'The trigger time parameter of the uncovered trash can is incorrect',
        2113: 'The parameter of vehicle stop time is wrong',
        2114: 'The trigger duration parameter of inactive timeout is wrong',
        2308: 'Data reporting type errors',
        2309: 'Insufficient graphics memory'
    }
};
