import { createApp, h } from 'vue';
import App from './App.vue';

/**
 * svg图标
 */
import svgIcon from './assets/svgIcons/index.vue';

/**
 * BreUI组件库
 */
import ArcoVue, { Message, Modal, Radio, RadioGroup } from '@bresee-ui/web-vue';
import ArcoVueIcon from '@bresee-ui/web-vue/es/icon'; // 额外引入图标库
/* Element-plus */
import { ElLoading } from 'element-plus';
import 'element-plus/dist/index.css';

/**
 * 国际化
 */
import i18n from '@/language/i18n';

/**
 * pinia状态管理器
 */
import { createPinia } from 'pinia';

/**
 * router路由管理
 */
import router from '@/router';
import { useUserStore } from '@/store/user';
import { useRouterStore } from '@/store/router';
import { usePermissionStore } from '@/store/permission';

/**
 * 公共样式CSS
 */
import './styles/index.scss';

/*
    自适应
*/
import { setDomFontSize } from '@/utils/dom';
import basicApi from '@/api/system-config/basic';
import to from 'await-to-js';
import VueSplide from '@splidejs/vue-splide';

const app = createApp(App).component('svg-icon', svgIcon);

// import '@bresee-ui/web-vue/dist/arco.css';
import('@arco-themes/vue-bresee/css/arco.css');
switch (localStorage.getItem('theme')) {
    case 'blue':
        document.body.style.setProperty('--primary-6', '39, 117, 182');
        document.body.style.setProperty('--primary-5', '52, 130, 196');
        document.body.style.setProperty('--primary-7', '31, 106, 168');
        document.body.style.setProperty('--primary-3', '203, 202, 202)');
        break;
    case 'green':
        document.body.style.setProperty('--primary-6', '66, 170, 145');
        document.body.style.setProperty('--primary-7', '46, 149, 124');
        document.body.style.setProperty('--primary-5', '83, 187, 162');
        document.body.style.setProperty('--primary-3', '203, 202, 202)');
        break;
    case 'purple':
        document.body.style.setProperty('--primary-6', '92, 90, 206');
        document.body.style.setProperty('--primary-5', '115, 113, 232');
        document.body.style.setProperty('--primary-7', '65, 63, 185');
        document.body.style.setProperty('--primary-3', '203, 202, 202');
        break;
    case 'orange':
        document.body.style.setProperty('--primary-6', '223, 178, 95');
        document.body.style.setProperty('--primary-5', '237, 193, 111');
        document.body.style.setProperty('--primary-7', '219, 169, 78');
        document.body.style.setProperty('--primary-3', '203, 202, 202');
        break;
    default:
        document.body.style.setProperty('--primary-6', '83 113 251');
        document.body.style.setProperty('--primary-5', '96, 125, 243');
        document.body.style.setProperty('--primary-7', '67, 95, 222');
        document.body.style.setProperty('--primary-3', '203, 202, 202');
}
app.use(ArcoVue);
app.use(ArcoVueIcon);

app.use(ElLoading);

app.use(i18n);

// 持久化存储
// import piniaPluginPersist from 'pinia-plugin-persist';
const store = createPinia();
// store.use(piniaPluginPersist);
app.use(store);

const useUser = useUserStore();
const useRouter = useRouterStore();
const usePermission = usePermissionStore();
const whiteList = ['/login', '/template', '/license', '/license-overdue', '/data-panel'];
useRouter.initRoutes();
// 路由判断登录 根据路由配置文件的参数
router.beforeEach(async (to, from, next) => {
    const hasToken = sessionStorage.getItem('token');
    if (whiteList.indexOf(to.path) !== -1) {
        document.title = i18n.global.t(to.meta.title);
        next();
    } else {
        if (hasToken) {
            if (to.path === '/license' || to.path === '/template') {
                next();
            } else {
                const hasRoles = useUser.roles && useUser.roles.length > 0 && useUser.roles[0];
                if (hasRoles) {
                    if (to.meta.title !== undefined) {
                        document.title = i18n.global.t(to.meta.title);
                    } else {
                        document.title = i18n.global.t('content.appTitle');
                    }
                    useRouter.initChildRoutes(to.path);
                    next();
                } else {
                    try {
                        useUser.getInfo();
                        const accessRoutes: any = await usePermission.generateRoutes(['admin']);
                        for (const k in accessRoutes) {
                            router.addRoute(accessRoutes[k]);
                            router.options.routes.push(accessRoutes[k]);
                        }
                        if (sessionStorage.getItem('hideRouteStateData') === 'open') {
                            useRouter.addHideRoutesData();
                        }
                        if (sessionStorage.getItem('hideWorkRouteState') === 'open') {
                            useRouter.addWorkClothesRoutes();
                        }
                        next({ ...to, replace: true });
                    } catch (error) {
                        sessionStorage.removeItem('token');
                        Message.error(i18n.global.t('content.login.timeout'));
                        next('/login');
                    }
                }
            }
        } else {
            sessionStorage.removeItem('token');
            Message.error(i18n.global.t('content.login.timeout'));
            next('/login');
        }
    }
});

/** 路由跳转时定位到页面顶部 */
router.afterEach(() => {
    window.scrollTo(0, 0);
});
app.use(router);
//全局注册方法
app.config.globalProperties.setItem = (key: string, newVal: any) => {
    if (key === 'token') {
        const newStorageEvent = document.createEvent('StorageEvent');
        const storage = {
            setItem: (k: string, val: any) => {
                sessionStorage.setItem(k, val);
                newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, '', null);
                window.dispatchEvent(newStorageEvent);
            }
        };
        return storage.setItem(key, newVal);
    }
};

setDomFontSize();
// 防抖自定义指令
app.directive('throttle', {
    mounted(el, binding) {
        el.addEventListener('click', () => {
            if (!el.disabled) {
                el.disabled = true;
                setTimeout(() => {
                    el.disabled = false;
                }, binding.value || 1000);
            }
        });
    }
});

document.onkeydown = (e) => {
    let keyCode = e.keyCode;
    let ctrlKey = e.ctrlKey || e.metaKey;
    let shiftKey = e.shiftKey;
    let altKey = e.altKey;
    if (ctrlKey && shiftKey && altKey && keyCode === 90) {
        let hideRouteState = sessionStorage.getItem('hideRouteStateData') || 'hide';
        if (hideRouteState === 'hide') {
            useRouter.addHideRoutesData();
        } else {
            useRouter.hideRoutesData();
        }
    }
    //海外版本隐藏人员库 布控任务 布控数据
    const mode: any = sessionStorage.getItem('version_mode');
    if (ctrlKey && shiftKey && altKey && keyCode === 88 && mode?.indexOf('sea') > -1) {
        // 海外4路算法模式；0：人脸，1：行为
        const isfinfo: any = sessionStorage.getItem('isfinfo');
        if (isfinfo !== undefined && (mode?.indexOf('4_hd_sea') > -1 || mode?.indexOf('4_no_sea') > -1)) {
            let finfoMode: Number;
            Modal.open({
                title: i18n.global.t('content.mode.title'),
                hideCancel: true,
                maskClosable: false,
                content: () =>
                    h('div', { style: 'text-align: center' }, [
                        h(
                            RadioGroup,
                            {
                                defaultValue: Number(isfinfo),
                                onChange: (val) => {
                                    finfoMode = Number(val);
                                }
                            },
                            [
                                h(Radio, { value: 0 }, i18n.global.t('content.mode.modeFace')),
                                h(
                                    Radio,
                                    {
                                        value: 1,
                                        style: 'margin-left: 10px'
                                    },
                                    i18n.global.t('content.mode.modeBehavior')
                                )
                            ]
                        ),
                        h('div', { style: 'margin-top: 10px; color: red' }, i18n.global.t('content.mode.desc'))
                    ]),
                okLoading: false,
                onBeforeOk: async () => {
                    const [errInfo, resInfo]: any = await to(
                        basicApi.setVersionMode({
                            mode: finfoMode,
                            is_force: true
                        })
                    );
                    if (errInfo) {
                        return false;
                    }
                    if (resInfo) {
                        sessionStorage.setItem('isfinfo', finfoMode.toString());
                        router.push('/id-manage').then(() => {
                            location.reload();
                        });
                        return true;
                    }
                }
            });
        } else {
            // let hideRouteState = sessionStorage.getItem('hideEnRouteStateData') || 'hide';
            // if (hideRouteState === 'hide') {
            //     useRouter.addHideEnRoutesData();
            // } else {
            //     useRouter.hideEnRoutesData();
            // }
        }
    }
};

window.addEventListener('storage', (e: any) => {
    if (e.key === 'theme') {
        return;
    }
    sessionStorage.setItem(e.key, e.oldValue);
    localStorage.setItem(e.key, e.oldValue);
});

app.use(VueSplide);
app.mount('#app');
