<template>
    <bre-layout-header>
        <div class="navbar-container">
            <div class="left">
                <p class="title">
                    <bre-tooltip :content="systemTitle" position="bottom">
                        <span>{{systemTitle}}</span>
                    </bre-tooltip>
                </p>
                <div class="breadcrumb-container">
                    <Breadcrumb :items="items" />
                </div>
            </div>
            <div class="right">
                <div class="data-dashboard">
                    <bre-button type="primary" shape="round" @click="toDataPanel">
                        <template #icon>
                            <!-- <icon-bar-chart /> -->
                            <svg-icon name="data-board"></svg-icon>
                        </template>
                        <template #default>{{t('content.router.dataPanel')}}</template>
                    </bre-button>
                </div>
                <div class="time">
                    <bre-button type='secondary' shape="round">
                        {{ currentDate }}
                    </bre-button>
                </div>
                <div class="change-lang">
                    <lang-select></lang-select>
                </div>
                <div class="change-theme">
                    <bre-popover class="change-theme-popover" title="Title" trigger="click">
                        <bre-button type="secondary" shape="round">
                            <template #icon>
                                <svg-icon name="skin" :color="currentTheme=='dark'?'#fff':'rgb(var(--primary-6))'"></svg-icon>
                            </template>
                            <template #default>{{t('content.changeTheme')}}</template>
                        </bre-button>
                        <template #content>
                            <div v-for="(item,index) in themeList" :key="item" class="color-item" :class="currentTheme===item.name?'active':''"
                                :style="{background:item.color,marginRight:(index+1)%3==0?'0px':'0.910rem'}" @click="changeTheme(item)">
                                <img src="@/assets/images/color-selected.png">
                            </div>
                        </template>
                    </bre-popover>

                </div>
                <div class="avatar">
                    <!-- <img src="@/assets/images/icon-upload.png" alt="" /> -->
                    <div class="user">
                        <bre-dropdown>
                            <bre-button type="text" class="logout-text">
                                <span>{{name}}</span>
                                <icon-down />
                            </bre-button>
                            <template #content>
                                <!-- <bre-doption @click="resetPwd">{{t('content.navBar.modifyPwd')}}</bre-doption> -->
                                <bre-doption @click="onLogout">{{t('content.navBar.logout')}}</bre-doption>
                            </template>
                        </bre-dropdown>
                    </div>
                </div>
            </div>
        </div>
        <ResetPwd v-if="resetPwdVisible" :visible="resetPwdVisible" @close="closeResetPwd" @submit="submitResetPwd"></ResetPwd>
        <bre-modal :visible="logoutVisible" :title="t('content.navBar.logout')" @cancel="closeLogoutVisible" :mask-closable="false" :closable="false">
            <div style="text-align:center;font-size:14px;">{{t('content.navBar.logoutTips')}}</div>
            <template #footer>
                <bre-button type="primary" class="ok" @click="logout">{{t('content.button.confirm')}}</bre-button>
                <bre-button type="outline" class="cancel" @click="closeLogoutVisible">
                    {{t('content.button.cancel')}}
                </bre-button>
            </template>
        </bre-modal>
    </bre-layout-header>
</template>

<script lang="ts" setup>
import LangSelect from '@/components/LangSelect/index.vue';
import { formatDate } from '@/utils/func';
import {
    ref,
    computed,
    onMounted,
    onUnmounted,
    watch,
    getCurrentInstance
} from 'vue';
import { useRouter } from 'vue-router';
import { useAppStore } from '@/store/app';
// import { useRouterStore } from '@/store/router';
import Breadcrumb from '@/layout/component/Breadcrumb/index.vue';
import ResetPwd from './ResetPwd.vue';
import { useI18n } from 'vue-i18n';
import loginApi from '@/api/login';
import serviceApi from '@/api/system-config/service';
import to from 'await-to-js';
import { Message } from '@bresee-ui/web-vue';
const useApp = useAppStore();
const router = useRouter();
const { t } = useI18n();
const items = computed(() => {
    const tmp = router.currentRoute.value.matched.filter(
        (item) => item.meta && JSON.stringify(item.meta) !== '{}'
    );
    return tmp.map((item) => item.meta.title);
});

// 跳至数据看板
const toDataPanel = () => {
    const routeData: any = router.resolve({ path: '/data-panel' });
    window.open(routeData.href);
};

//切换语言
// const changeLanguage = (e) => {
//     console.log(e);
// };

// 用户名
const name = ref('');
name.value = sessionStorage.getItem('name');
const instance: any = getCurrentInstance();
let global: any = {};
if (instance) {
    global = instance.appContext.config.globalProperties;
}
// 退出登录
const logoutVisible = ref(false);
const onLogout = () => {
    logoutVisible.value = true;
};
const closeLogoutVisible = () => {
    logoutVisible.value = false;
};
// 退出登录
const logout = async () => {
    const payload = {
        name: sessionStorage.getItem('name'),
        token: sessionStorage.getItem('token')
    };
    const [err, res] = await to(loginApi.logout(payload));
    if (err) {
        return;
    }
    if (res) {
        closeLogoutVisible();
        router.replace({ path: '/login' }).then(() => {
            location.reload();
        });
        sessionStorage.setItem('name', '');
        global && global.setItem('token', '');
        sessionStorage.setItem('hideRouteState', 'hide');
        sessionStorage.setItem('hideRouteStateData', 'hide');
    }
};

// 修改密码
const resetPwdVisible = ref(false);
// const resetPwd = () => {
//     resetPwdVisible.value = true;
// };
const closeResetPwd = () => {
    resetPwdVisible.value = false;
};
const submitResetPwd = async (data) => {
    const id = sessionStorage.getItem('userId');
    const [err, res] = await to(loginApi.updatePassword(id, data));
    if (err) {
        return;
    }
    if (res) {
        Message.success('修改成功');
        router.push('/login');
    }
};

onMounted(() => {
    setTimeout(() => {
        getSystemTile();
    });
});
// 获取标题
const systemTitle = ref(t('content.appTitle'));
const getSystemTile = async () => {
    const [err, res] = await to(serviceApi.getTitle());
    if (err) {
        return;
    }
    if (res) {
        if (res.data.nav_title) {
            systemTitle.value = res.data.nav_title;
        } else {
            systemTitle.value = t('content.appTitle');
        }
    }
};
watch(
    () => useApp.titleSync,
    (value) => {
        if (value) {
            setTimeout(() => {
                getSystemTile();
            });
            useApp.setTitleSync(false);
        }
    }
);
// 修改主题
const themeList = ref([
    {
        name: 'default',
        color: '#3399FF'
    },
    {
        name: 'blue',
        color: '#2775B6'
    },
    {
        name: 'green',
        color: '#42AA91'
    },
    {
        name: 'purple',
        color: '#5C5ACE'
    },
    {
        name: 'orange',
        color: '#DFB25F'
    },
    {
        name: 'dark',
        color: '#000'
    }
]);

const currentTheme = ref('default');
const changeTheme = async (item: any) => {
    currentTheme.value = item.name;
    useApp.setTheme(item.name);
    localStorage.setItem('theme', item.name);
    document.body.setAttribute('arco-theme', item.name);
    switch (item.name) {
        case 'blue':
            document.body.style.setProperty('--primary-6', '39, 117, 182');
            document.body.style.setProperty('--primary-5', '52, 130, 196');
            document.body.style.setProperty('--primary-7', '31, 106, 168');
            document.body.style.setProperty('--primary-3', '203, 202, 202)');
            break;
        case 'green':
            document.body.style.setProperty('--primary-6', '66, 170, 145');
            document.body.style.setProperty('--primary-7', '46, 149, 124');
            document.body.style.setProperty('--primary-5', '83, 187, 162');
            document.body.style.setProperty('--primary-3', '203, 202, 202)');
            break;
        case 'purple':
            document.body.style.setProperty('--primary-6', '92, 90, 206');
            document.body.style.setProperty('--primary-5', '115, 113, 232');
            document.body.style.setProperty('--primary-7', '65, 63, 185');
            document.body.style.setProperty('--primary-3', '203, 202, 202');
            break;
        case 'orange':
            document.body.style.setProperty('--primary-6', '223, 178, 95');
            document.body.style.setProperty('--primary-5', '237, 193, 111');
            document.body.style.setProperty('--primary-7', '219, 169, 78');
            document.body.style.setProperty('--primary-3', '203, 202, 202');
            break;
        case 'dark':
            document.body.style.setProperty('--primary-6', '51, 153, 255');
            document.body.style.setProperty('--primary-5', '80, 168, 255');
            document.body.style.setProperty('--primary-7', '67, 129, 222');
            document.body.style.setProperty('--primary-3', '168, 211, 255');
            break;
        default:
            document.body.style.setProperty('--primary-6', '51, 153, 255');
            document.body.style.setProperty('--primary-5', '80, 168, 255');
            document.body.style.setProperty('--primary-7', '67, 129, 222');
            document.body.style.setProperty('--primary-3', '168, 211, 255');
    }
};
// 获取时间
onMounted(() => {
    getTimeConfig();
    currentTheme.value = localStorage.getItem('theme') || 'default';
    // document.addEventListener('visibilitychange', function () {
    //     if (!document.hidden) {
    //         clearInterval(timer.value);
    //         getTimeConfig();
    //     }
    // });
});
onUnmounted(() => {
    clearInterval(timer.value);
    timer.value = null;
});
const timer: any = ref(null);
const currentDate = ref();
const getTimeConfig = async () => {
    const [err, res]: any = await to(serviceApi.getTimeConfig());
    if (err) {
        return;
    }
    if (res) {
        currentDate.value = formatDate(
            new Date(new Date(res.data.utc).getTime()),
            ' / '
        );
        let date = new Date(res.data.utc).getTime();
        timer.value = setInterval(() => {
            currentDate.value = formatDate(new Date(date + 1000), ' / ');
            date += 1000;
        }, 1000);
    }
};
</script>

<style lang="scss" scoped>
.navbar-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    float: left;
    overflow: auto;
    width: 100%;
    height: 100px;
    align-content: center;
    .left {
        position: absolute;
        top: 29px;
        left: 40px;
        display: flex;
        align-items: center;
        height: 55px;
        .title {
            overflow: hidden;
            margin: 0;
            width: 555px;
            height: 42px;
            font-size: 30px;
            font-weight: 600;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 42px;
            letter-spacing: 0px;
            @include theme {
                color: t('title-color');
            }
        }
        .breadcrumb-container {
            // position: absolute;
            // top: 0;
            // left: 300px;
        }
    }
    .right {
        position: absolute;
        top: 29px;
        right: 40px;
        display: flex;
        align-items: center;
        height: 55px;
        .change-theme {
            width: 150px;
        }
        .data-dashboard,
        .time,
        .change-lang,
        .change-theme,
        .avatar {
            margin-left: 20px;
        }
        .time,
        .change-lang,
        .change-theme,
        .avatar {
            button {
                width: 100%;
                @include theme {
                    color: t('nav-font-color');
                    background: t('nav-background-color');
                }
            }
        }
        .data-dashboard {
            .arco-btn-primary {
                width: 143px;
            }
        }
        .change-lang {
            .arco-btn-secondary {
                border-radius: 20px;
            }
        }
        .avatar {
            display: flex;
            align-items: center;
            img {
                width: 42px;
                height: 42px;
                border-radius: 42px;
            }
            .user {
                .logout-text {
                    width: 90px;
                    span {
                        width: 80px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    @include theme {
                        color: t('title-color');
                    }
                    background: transparent;
                }
            }
        }
        .icon-data-board,
        .icon-skin {
            margin: auto;
            width: 16px;
            height: 16px;
        }
        .icon-skin {
            color: #5371fb;
        }
    }
}
</style>
<style lang="scss">
.change-theme-popover {
    .arco-popover-title {
        display: none;
    }
    .arco-popover-popup-content {
        padding: 16px;
        width: 120px;
        height: 82px;
        min-width: 120px;
        min-height: 82px;
        padding-bottom: 0px;
        box-sizing: border-box;
    }
    .arco-popover-content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0px;
    }
    .color-item {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        width: 16px;
        height: 16px;
        min-width: 16px;
        max-height: 16px;
        margin-bottom: 13px;
        cursor: pointer;
        img {
            display: none;
            width: 8px;
            height: 8px;
        }
    }
    .color-item.active {
        img {
            display: block;
        }
    }
    .color-item:nth-child(3n + 0) {
        margin-right: 0px;
    }
}
</style>
