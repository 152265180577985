<template>
    <div class="lang-select-container">
        <bre-button v-if="mode.indexOf('sea')>-1">{{getLang=='en'?t('content.changeLang.en'):t('content.changeLang.zh')}}
            <icon-down /></bre-button>
        <bre-dropdown v-else @select="changeLang" v-model="basicForm.language">
            <!-- <img src="@/assets/images/lang-select.png" alt="" /> -->
            <bre-button>{{getLang=='en'?t('content.changeLang.en'):t('content.changeLang.zh')}}
                <icon-down />
            </bre-button>
            <template #content>
                <bre-doption value="zh" :disabled="getLang === 'zh'">{{
                    t('content.changeLang.zh')
                }}</bre-doption>
                <bre-doption value="en" :disabled="getLang === 'en'">{{
                    t('content.changeLang.en')
                }}</bre-doption>
            </template>
        </bre-dropdown>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '@/store/app/index';
import { Message } from '@bresee-ui/web-vue';
import Cookies from 'js-cookie';
import systemApi from '@/api/system-config/system';
import to from 'await-to-js';
const store = useAppStore();
const { locale, t } = useI18n();
const getLang = computed(() => store.lang);
const changeLang = async (lang: string) => {
    await saveBasis(lang);
};
const basicForm: any = ref({
    language: 'zh'
});
const saveBasis = async (lang: string) => {
    const [err, res]: any = await to(systemApi.getBase());
    if (err) {
        return;
    }
    if (res) {
        basicForm.value = res.data;
        basicForm.value.language = lang;
        basicForm.value.lan = lang === 'zh' ? 0 : 1;
    }
    systemApi.updateBase(basicForm.value).then((res: any) => {
        if (res.code === 200) {
            store.setLang(lang);
            sessionStorage.setItem('locale', lang);
            Cookies.set('language', lang);
            locale.value = lang;
            Message.success(t('content.changeLang.message'));
            location.reload();
            //判断当前路由是否存在
            // const currentPath = router.currentRoute.value.path;
            // const role = useRouterApp.routes.filter((item: any) => {
            //     const isParent = currentPath.includes(item.path);
            //     if (!isParent) {
            //         return false;
            //     }
            //     const filter = get(item, 'children', []).filter(
            //         (child: any) => child.path === currentPath
            //     );
            //     return filter.length > 0;
            // });
            // if (role.length === 0) {
            //     router.push({ path: '/id-manage' }).then(() => {
            //         location.reload();
            //     });
            // } else {
            //     location.reload();
            // }
        } else {
            Message.error(t('content.changeLang.messageFail'));
        }
    });
};
const mode: any = sessionStorage.getItem('version_mode');
onMounted(async () => {
    const [err, res]: any = await to(systemApi.getBase());
    if (err) {
        return;
    }
    if (res) {
        basicForm.value = res.data;
        store.setLang(res.data.lan === 0 ? 'zh' : 'en');
    }
});
</script>

<style lang="scss" coped>
.lang-select-container {
    // width: 131px;
    height: 31px;
    cursor: pointer;
    button {
        @include theme {
            color: t('nav-font-color');
            background: t('nav-background-color');
        }
    }
    .arco-btn-secondary {
        width: 140px;
        justify-content: space-around;
        border-radius: 20px;
    }
}
</style>
