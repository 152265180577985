<template>
    <div id="app">
        <bre-config-provider :locale="langUI === 0 ? zhCN : enUS">
            <ConfigProvider componentConfig={componentConfig}>
                <router-view v-if="isRouterAlive"></router-view>
            </ConfigProvider>
        </bre-config-provider>
    </div>
</template>
<script lang="ts" setup>
import { onMounted, ref, nextTick, provide } from 'vue';
import loginApi from '@/api/login';
import to from 'await-to-js';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import { useAppStore } from '@/store/app';
import { Message } from '@bresee-ui/web-vue';
import Cookies from 'js-cookie';
import systemApi from '@/api/system-config/system';
import enUS from '@bresee-ui/web-vue/es/locale/lang/en-us';
import zhCN from '@bresee-ui/web-vue/es/locale/lang/zh-cn';
const useApp = useAppStore();
const router = useRouter();
onMounted(async () => {
    const theme = localStorage.getItem('theme') || 'default';
    document.body.setAttribute('arco-theme', theme);
    if (theme === 'default') {
        document.body.style.setProperty('--primary-6', '51, 153, 255');
        document.body.style.setProperty('--primary-5', '80, 168, 255');
        document.body.style.setProperty('--primary-7', '67, 129, 222');
        document.body.style.setProperty('--primary-3', '168, 211, 255');
    }
    useApp.setTheme(theme);
    //初始化浏览器语言
    initLanguage();
    await getLanguage();
    const token = sessionStorage.getItem('token');
    if (token) {
        useApp.getWarnList();
    }
    window.addEventListener('setItem', () => {
        const token = sessionStorage.getItem('token');
        if (token) {
            validateLicense();
        }
    });
});
const isRouterAlive = ref(true);
const reload = () => {
    isRouterAlive.value = false;
    nextTick(() => {
        isRouterAlive.value = true;
    });
};
provide('reload', { reload });
//校验license
const validateLicense = async () => {
    const [err, res]: any = await to(loginApi.validateLicense());
    if (err) {
        Message.error(t(`content.statusCode.${err.code}`));
        return;
    }
    if (res) {
        if (res.data.flag === false) {
            sessionStorage.clear();
            router.replace('/license');
        }
    }
};

const langUI = ref();
const getLanguage = async () => {
    const [errInfo, resInfo]: any = await to(systemApi.getBase());
    if (errInfo) {
        return;
    }
    if (resInfo) {
        sessionStorage.setItem('zone', resInfo.data.zone);
        let lan = resInfo.data.lan === 0 ? 'zh' : 'en';
        langUI.value = resInfo.data.lan;
        Cookies.set('language', lan);
        sessionStorage.setItem('locale', lan);
        useApp.$patch((state) => {
            state.version_mode = resInfo.data.version_mode;
            state.lang = lan;
        });
        sessionStorage.setItem('version_mode', resInfo.data.version_mode);
        //若本地语言与服务器语言不一致
        if (perviousLocale !== lan) {
            location.reload();
        }
    }
};
let perviousLocale = sessionStorage.getItem('locale') || ''; //本地语言
//初始化浏览器语言
const initLanguage = () => {
    const language = navigator.language || '';
    let lan = language.indexOf('zh') > -1 ? 'zh' : 'en';
    langUI.value = language.indexOf('zh') > -1 ? 0 : 1;
    sessionStorage.setItem('locale', lan);
};
</script>
<style>
@font-face {
    font-family: shs;
    src: url('./fonts/SourceHanSansCN-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
#app {
    height: 100%;
    font-size: 14px;
    font-family: shs, Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* min-width: 1400px; */
}
</style>
